// React
import React, { useState } from 'react';

// Icons
import { FiEdit } from 'react-icons/fi';

// Helpers
import getAssetStatus from '../../helpers/widget/getAssetStatus';
import getHealthClass from '../../helpers/widget/getHealthClass';

const AssetList = ({
  filteredAssets,
  vppList,
  setTargetDetails,
  toggleAssetRealTimeData,
  toggleAssetDetails,
  toggleSecondaryDetails,
  toggleBessDashboard,
  toggleVppDashboard,
}) => {
  const [hoveredItemId, setHoveredItemId] = useState(false);

  const handleVppIdClick = (e, vppID) => {
    e.stopPropagation();
    
    const targetVpp = vppList.find(vpp => vpp.name === vppID);
    
    if (targetVpp) {
      setTargetDetails(targetVpp);
      toggleVppDashboard();
    }
  }

  return (
    <div className="border rounded-lg grow px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative">
      <table className="table-auto w-full">
        <thead className="table-head">
          <tr>
            <th className="table-head-item"></th>
            <th className="table-head-item">Asset Name</th>
            <th className="table-head-item">BESS Serial Number</th>
            <th className="table-head-item text-center">Battery SOC</th>
            <th className="table-head-item text-center">Status</th>
            <th className="table-head-item">Site Description</th>
            {/* <th className="table-head-item">VPP List</th> */}
            <th className="table-head-item">Account Name</th>
            <th className="table-head-item">Asset Contact</th>
            <th className="table-head-item">Model Number</th>
            <th className="table-head-item">Gateway SN</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets?.map((item, index) => {
            return (
              <tr
                key={item.assetId + index}
                className="border-b cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setTargetDetails(item);
                  if (item.enableUPS) {
                  toggleAssetRealTimeData()
                  }else if (item.enableBESS) {
                    toggleBessDashboard()
                  }else if (item.enableSecondary) {
                    toggleSecondaryDetails()
                  }
                }}
                onMouseEnter={() => setHoveredItemId(item.assetId)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                <td
                  className="pr-2 py-2"
                  onClick={(e) => {
                    e.stopPropagation();

                    setTargetDetails(item);
                    toggleAssetDetails();
                  }}
                >
                  <FiEdit
                    className={`inline-flex self-center ${hoveredItemId !== item.assetId && 'md:invisible'
                      }`}
                    size={20}
                  />
                </td>
                <td className="pr-2 py-2">{item.bessName}</td>
                <td className="pr-2 py-2 relative">
                  <span type="button" role="button">
                    {item.name}
                  </span>
                </td>
                <td className="pr-2 py-2 text-center">
                  {item.batterySOC?.toFixed(2)}
                </td>
                <td className="pr-2 py-2">
                  <div className="flex flex-row justify-center items-center align-middle whitespace-nowrap h-full">
                    <span
                      className={`health ${getHealthClass(
                        item.errorState
                      )} mr-2`}
                    ></span>
                    {getAssetStatus(item.errorState)}
                  </div>
                </td>
                <td className="pr-2 py-2">{item.siteDescription}</td>
                {/* <td
                  className="pr-2 py-2 underline"
                  onClick={(e) => handleVppIdClick(e, item.vppID)}
                >
                  {item.vppID}
                </td> */}
                <td className="pr-2 py-2">{item.accountName}</td>
                <td className="pr-2 py-2">{`${item.assetContactFirstName} ${item.assetContactLastName}`}</td>
                <td className="pr-2 py-2">{item.modelNumber}</td>
                <td className="pr-2 py-2">{item.gatewaySerialNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetList;
