// React
import React, { useEffect, useState } from 'react';

// Services
import {
  getAssetData as getAssetDataService,
  updateAssetData as updateAssetDataService,
} from '../../services/assetService';

// Components
import AssetDetailsInputField from '../assetDetails/AssetDetailsInputField';
import EnrollAssetToProgram from './programs/EnrollAssetToProgram';
import UnenrollAssetFromProgram from './programs/UnenrollAssetFromProgram';
import BessInformation from './bessInfo/BessInformation';
import VppInformation from './vppInfo/VppInformation';
import VppEnrollments from './vppEnrollments/VppEnrollments';
import AssetDrpTable from '../tables/AssetDrp';
import Loader from '../Loader';
import Modal from './Modal';
import Switcher from '../Switcher';
import StateDropdown from '../StateDropDown';

// Helpers
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import formatDateToGMT from '../../helpers/formatDate/formatDateToGMT';
import getFormatDashedDate from '../../helpers/formatDate/getFormatDashedDate';

// Access
import accessControl from '../../helpers/accessControl';

// Icons
import { FiX } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { BsInfoCircle } from 'react-icons/bs';

const EditAssetDetails = ({
  assetData = {},
  accounts = [],
  toggleAssetDetails,
  getDashboardData,
  isVppAssetDetails,
}) => {
  const [isAssetDataLoading, setIsAssetDataLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [showEnrollProgramModal, setShowEnrollProgramModal] = useState(false);
  const [showUnenrollProgramModal, setShowUnenrollProgramModal] =
    useState(false);
  const [activeProgram, setActiveProgram] = useState(null);
  const [lowSOCCutOffVoltageError, setLowSOCCutOffVoltageError] = useState('');
  const [isShowBessInfo, setIsShowBessInfo] = useState(false);
  const [isShowVppInfo, setIsShowVppInfo] = useState(false);
  const [isShowVppEnrollmentPage, setIsShowVppEnrollmentPage] = useState(false);
  // Asset difference from UTC in minutes
  const assetDifferenceFromUtc = getUtcOffsetDifference(
    formData?.gpsCoordinates?.latitude,
    formData?.gpsCoordinates?.longitude
  );

  useEffect(() => {
    fetchAssetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAssetData = async () => {
    try {
      const assetDataResponse = await getAssetDataService(assetData?.name);
      const assetDataObject = {
        ...assetDataResponse?.assetDetail[0],
        ...assetDataResponse?.metaData,
      };
      setFormData(assetDataObject);
    } catch (error) {
      console.error('Error fetching asset data:', error);
    } finally {
      setIsAssetDataLoading(false);
    }
  };

  const updateAssetData = async updatedData => {
    try {
      await updateAssetDataService(updatedData);
    } catch (error) {
      console.error('Error updating asset data:', error);
    }
  };

  const toggleEnrollModal = () => {
    setShowEnrollProgramModal(!showEnrollProgramModal);
  };

  const toggleUnenrollModal = () => {
    setShowUnenrollProgramModal(!showUnenrollProgramModal);
  };

  const toggleBessInfoModal = () => {
    setIsShowBessInfo(!isShowBessInfo);
  };

  const toggleVppInfoModal = () => {
    setIsShowVppInfo(!isShowVppInfo);
  };

  const toggleVppEnrollmentPage = () => {
    setIsShowVppEnrollmentPage(!isShowVppEnrollmentPage);
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'installDate') {
      const date = new Date(Date.parse(event.target.valueAsDate));
      const formattedDate = date.toUTCString();

      setFormData({
        ...formData,
        [name]: formattedDate,
      });
    }

    if (name === 'latitude' || name === 'longitude') {
      setFormData(prev => ({
        ...prev,
        gpsCoordinates: {
          ...prev.gpsCoordinates,
          [name]: value,
        },
      }));

      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleSwitcher = field => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: !prevFormData[field],
    }));
  };

  const submitForm = async () => {
    const dateForTWX = formatDateToGMT(formData.installDate);
    const newFormData = {
      siteDescription: formData.siteDescription,
      streetAddressEH: formData.streetAddressEH,
      locationIdentifier: formData.locationIdentifier,
      cityEH: formData.cityEH,
      stateEH: formData.stateEH,
      postalCodeEH: formData.postalCodeEH,
      countryEH: formData.countryEH,
      gpsCoordinates: {
        latitude: formData?.gpsCoordinates?.latitude,
        longitude: formData?.gpsCoordinates?.longitude,
      },
      installDate: dateForTWX,
      poNumber: formData.poNumber,
      assetContactFirstName: formData.assetContactFirstName,
      assetContactLastName: formData.assetContactLastName,
      assetContactPhone: formData.assetContactPhone,
      assetContactEmail: formData.assetContactEmail,
      assetContactRole: formData.assetContactRole,
      programTags: formData.programTags,
      fleetTags: formData.fleetTags,
      cadenzaTags: formData.cadenzaTags,
      notes: formData.notes,
      accountID: formData.accountID,
      modelNumber: formData.modelNumber,
      bessName: formData.bessName,
      assetName: formData.name,
      asset: formData.name,
      gatewaySerialNumber: formData.gatewaySerialNumber,
      maximumCapacity: formData.MaxCapacityKWh,
      dischargeCapacity: formData.dischargeCapacity,
      chargeCapacity: formData.chargeCapacity,
      utilityAccountNumberEH: formData.utilityAccountNumberEH,
      lowSOCCutOffVoltage: formData.lowSOCCutOffVoltage,
      deviceType: formData.DeviceType,
      manufacturer: formData.Manufacturer,
      model: formData.model,
      sourceAccountNumber: formData.accountOwningID,
      enableDPS: formData.enableDPS,
      timeIntervalForCyclicDPS: formData.timeIntervalForCyclicDPS,
      timeIntervalForMeterDPS: formData.timeIntervalForMeterDPS,
      idealPower: formData.idealPower,
      offSet: assetDifferenceFromUtc,
      enableBESS: formData.enableBESS,
      enableSecondary: formData.enableSecondary,
      secondaryName: formData.secondaryName,
      facilityType: formData.facilityType,
      enableUPS: formData.enableUPS,
      alwaysCharge: formData.alwaysCharge,
      alwaysChargePower: formData.alwaysChargePower,
    };

    // Check if lowSOCCutOffVoltage is empty
    if (!!!newFormData.lowSOCCutOffVoltage) {
      setLowSOCCutOffVoltageError(
        '"Low state of charge cutoff voltage (V)" is required'
      );
      return;
    } else if (
      newFormData.lowSOCCutOffVoltage < 40 ||
      newFormData.lowSOCCutOffVoltage > 55
    ) {
      setLowSOCCutOffVoltageError(
        '"Low state of charge cutoff voltage (V)" should be in the range of 40-55'
      );
      return;
    } else {
      setLowSOCCutOffVoltageError('');
    }
    updateAssetData(newFormData);

    setTimeout(getDashboardData, 1000);
  };

  return (
    <div className="modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer">
      <div
        className="container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto"
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <header className="py-7 sticky top-0 bg-white z-50 drop-shadow">
          <span
            className="absolute top-6 right-4"
            type="button"
            role="button"
            onClick={() => toggleAssetDetails()}
          >
            <FiX size={28} />
          </span>
          <h1 className="text-2xl font-bold text-center">
            Edit {formData.name ? formData.name : 'Asset'} Details
          </h1>
        </header>
        {isAssetDataLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Loader classNames="w-32 h-32" />
          </div>
        ) : (
          <div className="p-6 flex flex-col justify-between">
            <div className="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="edit-container mb-4 md:mb-0 md:row-span-2">
                <h2 className="text-lg font-bold mb-4">Asset Properties</h2>
                <div>
                  <AssetDetailsInputField
                    title="Site Description"
                    access={accessControl.isAdministrator()}
                    type="text"
                    name="siteDescription"
                    placeholder="Site Description"
                    inputValue={formData.siteDescription}
                    onChange={handleChange}
                    inputClassName="mt-3 mb-2 rounded-md w-full py-1 px-2"
                  />

                  <h3 className="mt-6 mb-4">Address</h3>
                  <div className="pl-4">
                    <AssetDetailsInputField
                      title="Street Address"
                      type="text"
                      name="streetAddressEH"
                      inputValue={`${formData.streetAddressEH}`}
                      placeholder="Address"
                      access={accessControl.isAdministrator()}
                      inputClassName="mt-3 mb-4 rounded-md w-full py-1 px-2"
                      titleClassName=" "
                      onChange={handleChange}
                    />
                    {!isVppAssetDetails && (
                      <AssetDetailsInputField
                        title="Location Identifier"
                        type="text"
                        name="locationIdentifier"
                        inputValue={`${formData.locationIdentifier}`}
                        placeholder="Room/Apartment/Section/Floor..."
                        access={accessControl.isAdministrator()}
                        inputClassName="mt-3 mb-4 rounded-md w-full py-1 px-2"
                        titleClassName=" "
                        onChange={handleChange}
                      />
                    )}
                    <AssetDetailsInputField
                      title="City"
                      type="text"
                      name="cityEH"
                      inputValue={`${formData.cityEH}`}
                      placeholder="City"
                      access={accessControl.isAdministrator()}
                      inputClassName="mt-3 mb-4 rounded-md w-full py-1 px-2"
                      titleClassName=" "
                      onChange={handleChange}
                    />
                    <StateDropdown
                      title="State"
                      name="stateEH"
                      access={accessControl.isAdministrator()}
                      inputValue={formData.stateEH}
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title="Postal Code"
                      type="text"
                      name="postalCodeEH"
                      inputValue={`${formData.postalCodeEH}`}
                      placeholder="Postal Code"
                      access={accessControl.isAdministrator()}
                      inputClassName="mt-3 mb-4 rounded-md w-full py-1 px-2"
                      titleClassName=" "
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title="Country"
                      type="text"
                      name="countryEH"
                      inputValue={`${formData.countryEH}`}
                      placeholder="Country"
                      access={accessControl.isAdministrator()}
                      inputClassName="mt-3 mb-4 rounded-md w-full py-1 px-2"
                      titleClassName=" "
                      onChange={handleChange}
                    />
                  </div>

                  <h3 className="mt-4 mb-4">GPS Coordinates</h3>
                  <div className="pl-4">
                    <AssetDetailsInputField
                      title="Latitude"
                      type="text"
                      name="latitude"
                      inputValue={`${formData?.gpsCoordinates?.latitude}`}
                      placeholder="0"
                      access={accessControl.isAdministrator()}
                      inputClassName="mt-3 mb-4 rounded-md w-full py-1 px-2"
                      titleClassName=" "
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title="Longitude"
                      type="text"
                      name="longitude"
                      inputValue={`${formData?.gpsCoordinates?.longitude}`}
                      placeholder="0"
                      access={accessControl.isAdministrator()}
                      inputClassName="my-3 rounded-md w-full py-1 px-2"
                      titleClassName=" "
                      onChange={handleChange}
                    />
                  </div>
                  <AssetDetailsInputField
                    title="Installation Date"
                    type="date"
                    name="installDate"
                    inputValue={getFormatDashedDate(formData.installDate)}
                    access={accessControl.isAdministrator()}
                    onChange={handleChange}
                    inputClassName="mt-2 mb-3 rounded-md w-full py-1 px-2"
                  />
                  <AssetDetailsInputField
                    title="P.O. Number"
                    type="text"
                    name="poNumber"
                    inputValue={formData.poNumber}
                    placeholder="000000000"
                    access={accessControl.isAdministrator()}
                    onChange={handleChange}
                    inputClassName="mt-2 mb-3 rounded-md w-full py-1 px-2"
                  />
                </div>
              </div>
              <div className="md:col-span-2">
                <div className="md:grid md:grid-cols-2 gap-8">
                  <div className="edit-container mb-4 md:mb-0">
                    <h2 className="text-lg font-bold">Asset Contact</h2>
                    <div>
                      <AssetDetailsInputField
                        title="First Name"
                        type="text"
                        name="assetContactFirstName"
                        inputValue={formData.assetContactFirstName}
                        placeholder="First Name"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Last Name"
                        type="text"
                        name="assetContactLastName"
                        inputValue={formData.assetContactLastName}
                        placeholder="Last Name"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Phone"
                        type="tel"
                        name="assetContactPhone"
                        inputValue={formData.assetContactPhone}
                        placeholder="1-555-555-5555"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Email"
                        type="email"
                        name="assetContactEmail"
                        inputValue={formData.assetContactEmail}
                        placeholder="hello@email.com"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Title"
                        type="text"
                        name="assetContactRole"
                        inputValue={formData.assetContactRole}
                        placeholder="Title"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="edit-container mb-4 md:mb-0">
                    <h2 className="text-lg font-bold mb-4">Filtering Tags</h2>
                    <div>
                      <AssetDetailsInputField
                        title="Programs"
                        type="text"
                        name="programTags"
                        inputValue={formData.programTags}
                        placeholder="Programs"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Fleets"
                        type="text"
                        name="fleetTags"
                        inputValue={formData.fleetTags}
                        placeholder="Fleets"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Tags"
                        type="text"
                        name="cadenzaTags"
                        inputValue={formData.cadenzaTags}
                        placeholder="Tags"
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />

                      {accessControl.isResident() ||
                      accessControl.isClientUser() ? (
                        <>
                          <span className="asset-title-data block mt-4 mb-1">
                            Notes
                          </span>
                          <p className="asset-data">
                            {formData.notes || 'No Data Available'}
                          </p>
                        </>
                      ) : (
                        <>
                          <h3 className="mt-4 mb-2">Notes</h3>
                          <textarea
                            type="text"
                            rows="4"
                            name="notes"
                            className="rounded-md w-full py-1 px-2"
                            placeholder="Notes"
                            value={`${formData.notes || ''}`}
                            onChange={handleChange}
                          ></textarea>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="edit-container mt-6">
                  <div className="flex items-center gap-1">
                    <h2 className="text-lg font-bold">
                      {isVppAssetDetails
                        ? 'VPP Information'
                        : 'BESS Information'}
                    </h2>
                    {accessControl.isPlatformAdministrator() && (
                      <IconContext.Provider value={{ color: '#00000' }}>
                        <BsInfoCircle
                          size={22}
                          onClick={
                            isVppAssetDetails
                              ? toggleVppInfoModal
                              : toggleBessInfoModal
                          }
                          title={
                            isVppAssetDetails
                              ? 'Show VPP information'
                              : 'Show BESS information'
                          }
                          cursor="pointer"
                        />
                      </IconContext.Provider>
                    )}
                  </div>
                  <div className="md:grid md:grid-cols-2 gap-8">
                    <div>
                      <h3 className="mt-2 mb-2 ">Account Name</h3>

                      {accessControl.isResident() ||
                      accessControl.isClientUser() ? (
                        <p>{formData.accountName || ''}</p>
                      ) : (
                        <select
                          name="accountID"
                          className="rounded-md w-full py-1 px-2"
                          onChange={handleChange}
                          defaultValue={formData.accountOwningID}
                        >
                          <option value="">Unassigned asset</option>
                          {accounts.map(account => {
                            return (
                              <option
                                key={account.accountID}
                                value={account.accountID}
                              >
                                {account.accountName}
                              </option>
                            );
                          })}
                        </select>
                      )}

                      <h3 className="mt-4 mb-2 ">
                        {isVppAssetDetails
                          ? 'VPP Serial Number'
                          : 'BESS Serial Number'}
                      </h3>
                      <p style={{ color: 'black' }}>
                        {formData.name || 'No Data Available'}
                      </p>

                      <h3 className="mt-4 mb-2">Cadenza Model Number</h3>
                      {accessControl.isPlatformAdministrator() ? (
                        <div className="relative">
                          <input
                            type="text"
                            name="modelNumber"
                            className="rounded-md w-full py-1 px-2"
                            placeholder="Cadenza Model Number"
                            value={`${formData.modelNumber || ''}`}
                            onChange={handleChange}
                          />
                          {assetData.modelNumber !== formData.modelNumber && (
                            <div className="color-warning absolute left-0 bottom-[-18px] text-xs lg:text-[10px] xl:text-xs">
                              Warning! Changing this field is dangerous.
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>{formData.modelNumber || ''}</p>
                      )}

                      {!isVppAssetDetails && (
                        <div className="mt-4">
                          <AssetDetailsInputField
                            title="Low state of charge cutoff voltage (V)"
                            access={accessControl.isPlatformAdministrator()}
                            type="text"
                            name="lowSOCCutOffVoltage"
                            inputValue={formData.lowSOCCutOffVoltage}
                            titleClassName="block mb-1 text-gray-600"
                            inputClassName={`rounded-md w-full py-1 px-2 ${
                              accessControl.isPlatformAdministrator()
                                ? ''
                                : 'bg-gray-200'
                            }`}
                            onChange={handleChange}
                          />
                          {lowSOCCutOffVoltageError && (
                            <div className="color-warning text-xs">
                              {lowSOCCutOffVoltageError}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="md:grid md:grid-cols-2 gap-8">
                        {accessControl.isPlatformAdministrator() &&
                          !isVppAssetDetails && (
                            <div className="flex items-center mt-6">
                              <h2 className="text-lg font-bold mr-4">
                                Enable BESS
                              </h2>
                              <Switcher
                                checked={formData.enableBESS}
                                onChange={() => toggleSwitcher('enableBESS')}
                                disabled={
                                  !accessControl.isPlatformAdministrator()
                                }
                              />
                            </div>
                          )}
                        {accessControl.isPlatformAdministrator() &&
                          !isVppAssetDetails && (
                            <div className="flex items-center mt-6">
                              <h2 className="text-lg font-bold mr-4">
                                Enable UPS
                              </h2>
                              <Switcher
                                checked={formData.enableUPS}
                                onChange={() => toggleSwitcher('enableUPS')}
                                disabled={
                                  !accessControl.isPlatformAdministrator()
                                }
                              />
                            </div>
                          )}
                      </div>
                      {accessControl.isPlatformAdministrator() && (
                        <div className="mt-4">
                          <AssetDetailsInputField
                            title="Facility Type"
                            access={
                              accessControl.isPlatformAdministrator() &&
                              formData.enableSecondary
                            }
                            type="text"
                            name="facilityType"
                            placeholder="House/Site..."
                            inputValue={formData.facilityType}
                            titleClassName="block mb-1 text-gray-600"
                            inputClassName={`rounded-md w-full py-1 px-2 ${
                              accessControl.isPlatformAdministrator() &&
                              formData.enableSecondary
                                ? ''
                                : 'bg-gray-200'
                            }`}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <h3 className="mt-2 mb-2">Asset Name</h3>
                      {accessControl.isPlatformAdministrator() ? (
                        <div className="relative">
                          <input
                            type="text"
                            name="bessName"
                            className="rounded-md w-full py-1 px-2"
                            placeholder="Asset Name"
                            value={`${formData.bessName || ''}`}
                            onChange={handleChange}
                          />
                          {assetData.bessName !== formData.bessName && (
                            <div className="color-warning absolute left-0 bottom-[-18px] text-xs lg:text-[10px] xl:text-xs">
                              Warning! Changing this field is dangerous.
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>{formData.bessName || ''}</p>
                      )}

                      <h3 className="mt-4 mb-2 ">Asset Status</h3>
                      <p style={{ color: 'black' }}>{formData.status || ''}</p>

                      {isVppAssetDetails ? (
                        <div className="mt-4">
                          <AssetDetailsInputField
                            title="Low state of charge cutoff voltage (V)"
                            access={accessControl.isPlatformAdministrator()}
                            type="text"
                            name="lowSOCCutOffVoltage"
                            inputValue={formData.lowSOCCutOffVoltage}
                            titleClassName="block mb-2 text-gray-600"
                            inputClassName={`rounded-md w-full py-1 px-2 ${
                              accessControl.isPlatformAdministrator()
                                ? ''
                                : 'bg-gray-200'
                            }`}
                            onChange={handleChange}
                          />
                          {lowSOCCutOffVoltageError && (
                            <div className="color-warning text-xs">
                              {lowSOCCutOffVoltageError}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <h3 className="mt-4 mb-2 ">Gateway SN</h3>
                          {accessControl.isPlatformAdministrator() ? (
                            <div className="relative">
                              <input
                                type="text"
                                name="gatewaySerialNumber"
                                className="rounded-md w-full py-1 px-2"
                                placeholder="Gateway SN"
                                value={`${formData.gatewaySerialNumber || ''}`}
                                onChange={handleChange}
                              />
                              {assetData.gatewaySerialNumber !==
                                formData.gatewaySerialNumber && (
                                <div className="color-warning absolute left-0 bottom-[-18px] text-xs lg:text-[10px] xl:text-xs">
                                  Warning! Changing this field is dangerous.
                                </div>
                              )}
                            </div>
                          ) : (
                            <p>{formData.gatewaySerialNumber || ''}</p>
                          )}
                          {!isVppAssetDetails && (
                            <div className="edit-container flex gap-8 mt-4">
                              <div className="flex flex-col">
                                <h2 className="text-lg font-bold mb-2">
                                  Always charge
                                </h2>
                                <Switcher
                                  checked={formData.alwaysCharge}
                                  onChange={() =>
                                    toggleSwitcher('alwaysCharge')
                                  }
                                  disabled={!accessControl.isAdministrator()}
                                />
                              </div>
                              <div className="">
                                <AssetDetailsInputField
                                  title="Charge power (kW)"
                                  access={accessControl.isAdministrator()}
                                  type="text"
                                  name="alwaysChargePower"
                                  inputValue={formData.alwaysChargePower}
                                  titleClassName="block mb-1 text-gray-600"
                                  inputClassName={`rounded-md w-full py-1 px-2 ${
                                    accessControl.isAdministrator()
                                      ? ''
                                      : 'bg-gray-200'
                                  }`}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {accessControl.isPlatformAdministrator() &&
                        !isVppAssetDetails && (
                          <div className="flex items-center mt-6">
                            <h2 className="text-lg font-bold mr-4">
                              Enable Secondary
                            </h2>
                            <Switcher
                              checked={formData.enableSecondary}
                              onChange={() => toggleSwitcher('enableSecondary')}
                              disabled={
                                !accessControl.isPlatformAdministrator()
                              }
                            />
                          </div>
                        )}
                      {accessControl.isPlatformAdministrator() && (
                        <div className="mt-4">
                          <AssetDetailsInputField
                            title="Secondary Name"
                            access={
                              accessControl.isPlatformAdministrator() &&
                              formData.enableSecondary
                            }
                            type="text"
                            name="secondaryName"
                            placeholder="Solar/Wind..."
                            inputValue={formData.secondaryName}
                            titleClassName="block mb-1 text-gray-600"
                            inputClassName={`rounded-md w-full py-1 px-2 ${
                              accessControl.isPlatformAdministrator() &&
                              formData.enableSecondary
                                ? ''
                                : 'bg-gray-200'
                            }`}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="edit-container mt-6">
                    <h2 className="text-lg font-bold">Demand Response</h2>
                    <div className="md:grid md:grid-cols-4 gap-8 mt-2">
                      <AssetDetailsInputField
                        title="Service Account Number"
                        access={accessControl.isPlatformAdministrator()}
                        type="text"
                        name="utilityAccountNumberEH"
                        inputValue={formData.utilityAccountNumberEH}
                        titleClassName="block mb-1 text-gray-600"
                        inputClassName={`rounded-md w-full py-1 px-2 ${
                          accessControl.isPlatformAdministrator()
                            ? ''
                            : 'bg-gray-200'
                        }`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Storage Capacity (kWh)"
                        access={accessControl.isPlatformAdministrator()}
                        type="text"
                        name="MaxCapacityKWh"
                        inputValue={formData.MaxCapacityKWh}
                        titleClassName="block mb-1 text-gray-600"
                        inputClassName={`rounded-md w-full py-1 px-2 ${
                          accessControl.isPlatformAdministrator()
                            ? ''
                            : 'bg-gray-200'
                        }`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Charge Capacity (kW)"
                        access={accessControl.isPlatformAdministrator()}
                        type="text"
                        name="chargeCapacity"
                        inputValue={formData.chargeCapacity}
                        titleClassName="block mb-1 text-gray-600"
                        inputClassName={`rounded-md w-full py-1 px-2 ${
                          accessControl.isPlatformAdministrator()
                            ? ''
                            : 'bg-gray-200'
                        }`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title="Discharge Capacity (kW)"
                        access={accessControl.isPlatformAdministrator()}
                        type="text"
                        name="dischargeCapacity"
                        inputValue={formData.dischargeCapacity}
                        titleClassName="block mb-1 text-gray-600"
                        inputClassName={`rounded-md w-full py-1 px-2 ${
                          accessControl.isPlatformAdministrator()
                            ? ''
                            : 'bg-gray-200'
                        }`}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="md:grid md:grid-cols-2 gap-8">
                    <div className="edit-container mt-6">
                      <h2 className="text-lg font-bold">
                        Power Meter Configuration
                      </h2>
                      <div className="md:grid md:grid-cols-2 gap-8 mt-2">
                        <AssetDetailsInputField
                          title="DPS Scan time (min)"
                          access={accessControl.isPlatformAdministrator()}
                          type="text"
                          name="timeIntervalForCyclicDPS"
                          inputValue={formData.timeIntervalForCyclicDPS}
                          titleClassName="block mb-1 text-gray-600"
                          className={`rounded-md w-full py-1 px-2 ${
                            accessControl.isPlatformAdministrator()
                              ? ''
                              : 'bg-gray-200'
                          }`}
                          onChange={handleChange}
                        />
                        <AssetDetailsInputField
                          title="Power meter scan time (min)"
                          access={accessControl.isPlatformAdministrator()}
                          type="text"
                          name="timeIntervalForMeterDPS"
                          inputValue={formData.timeIntervalForMeterDPS}
                          titleClassName="block mb-1 text-gray-600"
                          inputClassName={`rounded-md w-full py-1 px-2 ${
                            accessControl.isPlatformAdministrator()
                              ? ''
                              : 'bg-gray-200'
                          }`}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="edit-container mt-6">
                      <div className="flex items-center">
                        <h2 className="text-lg font-bold mr-4">
                          Dynamic Peak Shaving
                        </h2>
                        <Switcher
                          checked={formData.enableDPS}
                          onChange={() => toggleSwitcher('enableDPS')}
                          disabled={!accessControl.isAdministrator()}
                        />
                      </div>
                      <div className="md:grid md:grid-cols-2 gap-8 mt-2">
                        <AssetDetailsInputField
                          title="Ideal Power (kW)"
                          access={
                            accessControl.isAdministrator() &&
                            formData.enableDPS
                          }
                          type="text"
                          name="idealPower"
                          inputValue={formData.idealPower}
                          titleClassName="block mb-1 text-gray-600"
                          inputClassName={`rounded-md w-full py-1 px-2 ${
                            formData.enableDPS &&
                            accessControl.isAdministrator()
                              ? ''
                              : 'bg-gray-200'
                          }`}
                          onChange={handleChange}
                        />
                        {Number(assetData.idealPower) !==
                          Number(formData.idealPower) && (
                          <div className="color-warning text-xs lg:text-[10px] xl:text-xs">
                            Warning! Ideal power change will affect the demand
                            charges
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end flex-col md:flex-row">
              <div>
                {isVppAssetDetails && (
                  <button
                    className="rounded-md px-4 py-2 text-sm mb-4 md:mb-0"
                    onClick={toggleVppEnrollmentPage}
                  >
                    Enrollments
                  </button>
                )}
                {accessControl.isAdministrator() && (
                  <button
                    className="rounded-md px-4 py-2 text-sm mb-4 md:mb-0 ml-8"
                    onClick={submitForm}
                  >
                    Update {isVppAssetDetails ? 'VPP' : 'Asset'} Details
                  </button>
                )}
              </div>
            </div>

            <div className="flex flex-col justify-end mt-6">
              <h2 className="text-lg font-bold mb-4">
                Demand Response Programs:
              </h2>
              <AssetDrpTable
                stateEH={formData.stateEH}
                setActiveProgram={setActiveProgram}
                setShowEnrollProgramModal={setShowEnrollProgramModal}
                setShowUnenrollProgramModal={setShowUnenrollProgramModal}
              />
            </div>
          </div>
        )}
      </div>

      {showEnrollProgramModal && activeProgram && (
        <Modal
          toggleModal={toggleEnrollModal}
          isSmall={true}
          child={
            <EnrollAssetToProgram
              activeAssets={[formData.assetName]}
              activeProgram={activeProgram}
              toggleModal={toggleEnrollModal}
            />
          }
        />
      )}

      {showUnenrollProgramModal && activeProgram && (
        <Modal
          toggleModal={toggleUnenrollModal}
          isSmall={true}
          child={
            <UnenrollAssetFromProgram
              activeAssets={[formData.assetName]}
              activeProgram={activeProgram}
              toggleModal={toggleUnenrollModal}
            />
          }
        />
      )}

      {isShowBessInfo && (
        <Modal
          toggleModal={toggleBessInfoModal}
          isSmall={true}
          child={
            <BessInformation
              assetName={formData.assetName}
              assetDetails={formData}
            />
          }
        />
      )}

      {isShowVppInfo && (
        <Modal
          toggleModal={toggleVppInfoModal}
          isSmall={true}
          child={
            <VppInformation
              assetName={formData.assetName}
              assetDetails={formData}
            />
          }
        />
      )}

      {isShowVppEnrollmentPage && (
        <Modal
          toggleModal={toggleVppEnrollmentPage}
          isSmall={false}
          child={<VppEnrollments assetName={formData.name} />}
        />
      )}
    </div>
  );
};

export default EditAssetDetails;
