import { useRef, useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import getTimezoneFromCoordinates from '../../helpers/formatDate/getTimezoneFromCoordinates';
import { validateAndConvertDatesToUTC } from '../../helpers/formatDate/validateAndConvertDatesToUTC';
import { idToText } from '../../helpers/widget/idToText';
import Widget from '../widgets/Widget';
import { useSelector, useDispatch } from 'react-redux';
import assetRealTimeDataOperations from '../../redux/assetRealTimeData/assetRealTimeDataOperations';
import { assetRealTimeDataSelectors } from '../../redux/assetRealTimeData/assetRealTimeDataSelectors';
import SolarYearly from '../widgets/SolarYearly';
import ChargingEvents from '../widgets/ChargingEvents';
import {
  getSolarAssetData,
  getUpsAssetData as getUpsAssetDataService,
} from '../../services/assetService';
import { getGridPowerStatusColors } from '../../helpers/icons/getGridPowerStatusColors';
import PowerSoc from '../widgets/PowerSoc';
import Loader from '../Loader';
import {
  fetchPerformanceData as fetchPerformanceDataService,
  fetchAccountLoadData,
  fetchAccountMeteringData,
  fetchAccountSolarData,
  fetchYearlyAccountLoadData,
  fetchYearlyAccountMeteringData,
  fetchYearlyAccountSolarData,
} from '../../services/graphService';
import { formatValue } from '../../helpers/icons/formatValue';
import {
  calculateAssetTimeRange,
  calculateDateRangeInUTC,
  calculateMonthlyAssetTimeRange,
  calculateMonthRangeInUTC,
  calculateWeeklyAssetTimeRange,
  calculateTableTimeRanges,
} from '../../helpers/formatDate/calculateAssetTimeRange';
import { userSelectors } from '../../redux/user/userSelectors';
import SolarDaily from '../widgets/SolarDaily';
import SolarWeekly from '../widgets/SolarWeekly';
import SolarMonthly from '../widgets/SolarMonthly';
import MeteringDaily from '../widgets/MeteringDaily';
import MeteringWeekly from '../widgets/MeteringWeekly';
import LoadDaily from '../widgets/LoadDaily';
import LoadWeekly from '../widgets/LoadWeekly';
import { ReactComponent as GridPowerStatus } from '../../assets/grid-power-status.svg';
import { ReactComponent as Battery } from '../../assets/battery_shape.svg';
import { ReactComponent as Thermometer } from '../../assets/thermometer_shape.svg';
import { ReactComponent as BatteryThroughput } from '../../assets/charging-time.svg';
import { ReactComponent as BatteryDataSoh } from '../../assets/battery-data-soh.svg';
import { ReactComponent as RoomTemperature } from '../../assets/room-temperature.svg';
import { ReactComponent as UpsInputHz } from '../../assets/ups-input-hz.svg';
import { ReactComponent as UpsInputPower } from '../../assets/ups-input-power.svg';
import { ReactComponent as UpsInputVoltage } from '../../assets/ups-input-voltage.svg';
import { ReactComponent as SolarPowerNow } from '../../assets/Solar_Power_Now.svg';
import { ReactComponent as ProductionToday } from '../../assets/Production_Today.svg';
import { ReactComponent as LifetimeProduction } from '../../assets/Lifetime_Production.svg';
import { ReactComponent as MyHouse } from '../../assets/My_House.svg';
import { ReactComponent as HouseMetering } from '../../assets/House_Metering.svg';
import { ReactComponent as SolarEnergy } from '../../assets/Solar_Energy.svg';
import { ReactComponent as TargetPower } from '../../assets/charging-target.svg';
import DefaultSingleBessLayout from '../../constants/DefaultSingleBessLayout';
import ScheduledEvents from '../widgets/ScheduledEvents';
import UnderIconBar from '../pageComponents/assetIconComponents/UnderIconBar';
import { getAssetData as getAssetDataService } from '../../services/assetService';
import Modal from '../modals/Modal';
import DeleteChargingEvent from '../modals/chargingEvents/DeleteChargingEvent';
import DeleteDischargingEvent from '../modals/dischargingEvents/DeleteDischargingEvent';
import OptOutDischargingEvent from '../modals/dischargingEvents/OptOutDischargingEvent';
import CreateDischargeEvent from '../modals/dischargingEvents/CreateDischargeEvent';
import CreateChargeEvent from '../modals/chargingEvents/CreateChargeEvent';
import DischargingEvents from '../widgets/DischargingEvents';

const SingleBESSContainer = ({ assetData: assetDetailsData = {} }) => {
  const modalRef = useRef();
  const now = new Date();
  const dispatch = useDispatch();
  const {
    name: assetName,
    secondaryName,
    enableSecondary,
    enableUPS,
    enableBESS,
  } = assetDetailsData;
  const [scrollPosition, setScrollPosition] = useState(0);
  const assetDifferenceFromUtc = getUtcOffsetDifference(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );
  const assetTimezone = getTimezoneFromCoordinates(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );
  const [upsIconsData, setUpsIconsData] = useState(null);
  const [solarIconsData, setSolarIconsData] = useState(null);
  const upsGridPowerStatus = upsIconsData?.UPSGridPowerStatus || 'N/A';
  const upsInputPower = formatValue(upsIconsData?.UPSInputPower);
  const upsInputVoltage = formatValue(upsIconsData?.UPSInputVoltage);
  const upsInputHz = formatValue(upsIconsData?.UPSInputHz);
  const batteryThroughput = formatValue(upsIconsData?.BatteryThroughput);
  const soc = formatValue(upsIconsData?.SOC);
  const soh = formatValue(upsIconsData?.SOH);
  const moduleTemperatureC = formatValue(
    upsIconsData?.BMSCellMaxTemperatureC,
    true
  );
  const moduleTemperatureF = formatValue(
    upsIconsData?.BMSCellMaxTemperatureF,
    true
  );
  const moduleTemperatureBar =
    moduleTemperatureC >= 50
      ? 100
      : Math.round(moduleTemperatureC * 2 + 15) || 'N/A';
  const roomTemperatureC = formatValue(
    upsIconsData?.InverterAmbientTemperatureC,
    true
  );
  const roomTemperatureF = formatValue(
    upsIconsData?.InverterAmbientTemperatureF,
    true
  );
  const [activeChargingEventID, setActiveChargingEventID] = useState(null);
  const [showAddChargeEvent, setShowAddChargeEvent] = useState(false);
  const [showAddDischargeEvent, setShowAddDischargeEvent] = useState(false);
  const [showDeleteChargingEventModal, setDeleteChargingEventModal] =
    useState(false);
  const [showDeleteDischargingEventModal, setDeleteDischargingEventModal] =
    useState(false);
  const startOfDay = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  endOfDay.setHours(23, 59, 59, 999);
  const [backupLoadAssetStartDate, setPowerAssetStartDate] =
    useState(startOfDay);
  const [backupLoadAssetEndDate, setPowerAssetEndDate] = useState(endOfDay);
  const [activeDischargingEventID, setActiveDischargingEventID] =
    useState(null);
  const [showOptOutEventModal, setShowOptOutEventModal] = useState(false);
  const [isPowerSocLoading, setIsPowerSocLoading] = useState(true);
  const [chargeStartDateTime, setChargeStartDateTime] = useState('');
  const [chargeEndDateTime, setChargeEndDateTime] = useState('');
  const [dischargeStartDateTime, setDischargeStartDateTime] = useState('');
  const [dischargeEndDateTime, setDischargeEndDateTime] = useState('');
  const [powerSocStartDate, setPowerSocStartDate] = useState('');
  const [powerSocEndDate, setPowerSocEndDate] = useState('');
  const [powerSoc, setPowerSoc] = useState([]);
  const [powerSocAssetStartDate, setPowerSocAssetStartDate] = useState('');
  const [powerSocAssetEndDate, setPowerSocAssetEndDate] = useState('');
  const [dailyPowerData, setDailyPowerData] = useState([]);
  const [dailyLoadData, setDailyLoadData] = useState([]);
  const [weeklyPowerData, setWeeklyPowerData] = useState([]);
  const [weeklyMeteringData, setWeeklyMeteringData] = useState([]);
  const [weeklyLoadData, setWeeklyLoadData] = useState([]);
  const [monthlyPowerData, setMonthlyPowerData] = useState([]);
  const [monthlyMeteringData, setMonthlyMeteringData] = useState([]);
  const [monthlyLoadData, setMonthlyLoadData] = useState([]);
  const [yearlyPowerData, setYearlyPowerData] = useState([]);
  const [yearlyMeteringData, setYearlyMeteringData] = useState([]);
  const [yearlyLoadData, setYearlyLoadData] = useState([]);
  const [dailyMeteringData, setDailyMeteringData] = useState([]);
  const [solarDailyStartDate, setSolarDailyStartDate] = useState('');
  const [solarDailyEndDate, setSolarDailyEndDate] = useState('');
  const [loadDailyStartDate, setLoadDailyStartDate] = useState('');
  const [loadDailyEndDate, setLoadDailyEndDate] = useState('');
  const [solarWeeklyStartDate, setSolarWeeklyStartDate] = useState('');
  const [solarWeeklyEndDate, setSolarWeeklyEndDate] = useState('');
  const [meteringWeeklyStartDate, setMeteringWeeklyStartDate] = useState('');
  const [meteringWeeklyEndDate, setMeteringWeeklyEndDate] = useState('');
  const [loadWeeklyStartDate, setLoadWeeklyStartDate] = useState('');
  const [loadWeeklyEndDate, setLoadWeeklyEndDate] = useState('');
  const [meteringDailyStartDate, setMeteringDailyStartDate] = useState('');
  const [meteringDailyEndDate, setMeteringDailyEndDate] = useState('');
  const [IsMonthlySolarLoading, setIsMonthlySolarLoading] = useState(true);
  const [IsMonthlyMeteringLoading, setIsMonthlyMeteringLoading] =
    useState(true);
  const [IsMonthlyLoadLoading, setIsMonthlyLoadLoading] = useState(true);
  const [isDailySolarLoading, setIsDailySolarLoading] = useState(true);
  const [isDailyLoadLoading, setIsDailyLoadLoading] = useState(true);
  const [isWeeklySolarLoading, setIsWeeklySolarLoading] = useState(true);
  const [isWeeklyMeteringLoading, setIsWeeklyMeteringLoading] = useState(true);
  const [isWeeklyLoadLoading, setIsWeeklyLoadLoading] = useState(true);
  const [isDailyMeteringLoading, setIsDailyMeteringLoading] = useState(true);
  const [isYearlySolarLoading, setIsYearlySolarLoading] = useState(true);
  const [isYearlyMeteringLoading, setIsYearlyMeteringLoading] = useState(true);
  const [isYearlyLoadLoading, setIsYearlyLoadLoading] = useState(true);
  const getChargingEventsLoading = useSelector(
    assetRealTimeDataSelectors.getChargingEventsLoading
  );
  const userDetails = useSelector(userSelectors.getUserDetailsData);
  const getDischargingEventsLoading = useSelector(
    assetRealTimeDataSelectors.getDischargingEventsLoading
  );

  const [meteringAssetStartDate, setMeteringAssetStartDate] = useState();
  const [meteringAssetEndDate, setMeteringAssetEndDate] = useState();
  const [loadAssetStartDate, setLoadAssetStartDate] = useState();
  const [loadAssetEndDate, setLoadAssetEndDate] = useState();
  const [powerAssetStartWeekDate, setPowerAssetStartWeekDate] = useState('');
  const [powerAssetEndWeekDate, setPowerAssetEndWeekDate] = useState('');
  const [meteringAssetStartWeekDate, setMeteringAssetStartWeekDate] =
    useState('');
  const [meteringAssetEndWeekDate, setMeteringAssetEndWeekDate] = useState('');
  const [loadAssetStartWeekDate, setLoadAssetStartWeekDate] = useState('');
  const [loadAssetEndWeekDate, setLoadAssetEndWeekDate] = useState('');

  const [assetDetails, setAssetDetails] = useState(null);

  const solarPowerNow = formatValue(solarIconsData?.solarPowerNow);
  const productionToday = formatValue(solarIconsData?.productionToday);
  const lifetimeProduction = formatValue(solarIconsData?.lifetimeProduction);
  const myHouseNow = formatValue(solarIconsData?.myHouseNow);
  const myHouseToday = formatValue(solarIconsData?.myHouseToday);
  const myHouseThisMonth = formatValue(solarIconsData?.myHouseThisMonth);
  const myHouseTillNow = formatValue(solarIconsData?.myHouseThisTillNow);
  const solarEnergyNow = formatValue(solarIconsData?.solarEnergyNow);
  const solarEnergyToday = formatValue(solarIconsData?.solarEnergyToday);
  const solarEnergyThisMonth = formatValue(
    solarIconsData?.solarEnergyThisMonth
  );
  const solarEnergyTillNow = formatValue(
    solarIconsData?.solarEnergyThisTillNow
  );
  const houseMeteringNow = formatValue(solarIconsData?.houseMeteringNow);
  const houseMeteringToday = formatValue(solarIconsData?.houseMeteringToday);
  const houseMeteringThisMonth = formatValue(
    solarIconsData?.houseMeteringThisMonth
  );
  const houseMeteringTillNow = formatValue(
    solarIconsData?.houseMeteringThisTillNow
  );
  const powerGoal = formatValue(assetDetails?.pGoal);
  const inverterStatus = solarIconsData?.inverterStatus || 'N/A';

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      modalRef.current?.scrollTo(0, scrollPosition);
    }, 0);
  };
  useEffect(() => {
    scrollToPreviousPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToPreviousPosition]);

  const handleScroll = () => {
    const position = modalRef.current.scrollTop;
    setScrollPosition(position);
  };

  // Fetch initial tables data
  const fetchInitialTablesData = () => {
    const { startDate, endDate, startOfDay, endOfNext7Days } =
      calculateTableTimeRanges(assetDifferenceFromUtc);

    const bodyRequest = {
      startDatetime: startDate,
      endDatetime: endDate,
      assetName: assetName,
    };

    // Fetch data for ChargingEvents
    dispatch(assetRealTimeDataOperations.getChargingEvents({ bodyRequest }));
    setChargeStartDateTime(startOfDay);
    setChargeEndDateTime(endOfNext7Days);

    // Fetch data for DischargingEvents
    dispatch(assetRealTimeDataOperations.getDischargingEvents({ bodyRequest }));
    setDischargeStartDateTime(startOfDay);
    setDischargeEndDateTime(endOfNext7Days);
  };

  const dateFilter = async (element, startDateParam, endParam) => {
    if (!element || !startDateParam) return;
    let endDateParam;

    if (
      element === 'dailySecondWattnodePower' ||
      element === 'dailyHouseMeteringPower' ||
      element === 'dailyHouseLoadPower'
    ) {
      endDateParam = new Date(startDateParam);
      endDateParam.setHours(23, 59, 59, 999);
    } else if (
      element === 'weeklySecondWattnodePower' ||
      element === 'weeklyHouseMeteringPower' ||
      element === 'weeklyHouseLoadPower'
    ) {
      endDateParam = new Date(startDateParam);
      endDateParam.setDate(endDateParam.getDate() + 6);
      endDateParam.setHours(0, 0, 0, 0);
    } else {
      endDateParam = endParam;
    }

    const { startToUTC, endToUTC, startOfDayUTC, endOfDayUTC } =
      calculateDateRangeInUTC(
        startDateParam,
        endDateParam,
        assetDifferenceFromUtc
      );

    handleScroll();
    if (element === 'dailySecondWattnodePower') {
      try {
        setIsDailySolarLoading(true);
        const dailyPowerResult = await fetchAccountSolarData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyPowerData(dailyPowerResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setSolarDailyStartDate(startDateParam);
        setSolarDailyEndDate(endDateParam);
        setPowerAssetStartDate(startOfDayUTC);
        setPowerAssetEndDate(endOfDayUTC);
        setIsDailySolarLoading(false);
      }
    }
    if (element === 'dailyHouseLoadPower') {
      try {
        setIsDailyLoadLoading(true);
        const dailyLoadResult = await fetchAccountLoadData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyLoadData(dailyLoadResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setLoadDailyStartDate(startDateParam);
        setLoadDailyEndDate(endDateParam);
        setLoadAssetStartDate(startOfDayUTC);
        setLoadAssetEndDate(endOfDayUTC);
        setIsDailyLoadLoading(false);
      }
    }
    if (element === 'dailyHouseMeteringPower') {
      try {
        setIsDailyMeteringLoading(true);
        const dailyMeteringPowerResult = await fetchAccountMeteringData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyMeteringData(dailyMeteringPowerResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setMeteringDailyStartDate(startDateParam);
        setMeteringDailyEndDate(endDateParam);
        setMeteringAssetStartDate(startOfDayUTC);
        setMeteringAssetEndDate(endOfDayUTC);
        setIsDailyMeteringLoading(false);
      }
    }

    if (element === 'weeklySecondWattnodePower') {
      try {
        setIsWeeklySolarLoading(true);
        const weeklyPowerResult = await fetchAccountSolarData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyPowerData(weeklyPowerResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setSolarWeeklyStartDate(startDateParam);
        setSolarWeeklyEndDate(endDateParam);
        setPowerAssetStartWeekDate(startOfDayUTC);
        setPowerAssetEndWeekDate(endOfDayUTC);
        setIsWeeklySolarLoading(false);
      }
    }

    if (element === 'weeklyHouseMeteringPower') {
      try {
        setIsWeeklyMeteringLoading(true);
        const weeklyMeteringResult = await fetchAccountMeteringData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyMeteringData(weeklyMeteringResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setMeteringWeeklyStartDate(startDateParam);
        setMeteringWeeklyEndDate(endDateParam);
        setMeteringAssetStartWeekDate(startOfDayUTC);
        setMeteringAssetEndWeekDate(endOfDayUTC);
        setIsWeeklyMeteringLoading(false);
      }
    }
    if (element === 'weeklyHouseLoadPower') {
      try {
        setIsWeeklyLoadLoading(true);
        const weeklyLoadResult = await fetchAccountLoadData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyLoadData(weeklyLoadResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoadWeeklyStartDate(startDateParam);
        setLoadWeeklyEndDate(endDateParam);
        setLoadAssetStartWeekDate(startOfDayUTC);
        setLoadAssetEndWeekDate(endOfDayUTC);
        setIsWeeklyLoadLoading(false);
      }
    }

    if (element === 'peakShaving') {
      try {
        setIsPowerSocLoading(true);
        const dpsPerformanceData = await fetchPerformanceDataService(
          assetName,
          startToUTC,
          endToUTC
        );
        setPowerSoc(dpsPerformanceData);
      } catch (error) {
        console.error('Error fetching performance data:', error);
      } finally {
        setPowerSocStartDate(startDateParam);
        setPowerSocEndDate(endDateParam);
        setPowerSocAssetStartDate(startOfDayUTC);
        setPowerSocAssetEndDate(endOfDayUTC);
        setIsPowerSocLoading(false);
      }
    }
  };

  const yearFilter = async (element, year) => {
    if (!element || !year) return;

    handleScroll();
    if (element === 'yearlySecondWattnodePower') {
      try {
        setIsYearlySolarLoading(true);
        const yearlySolarResult = await fetchYearlyAccountSolarData(
          assetName,
          year
        );
        setYearlyPowerData(yearlySolarResult);
      } catch (error) {
        console.error('Error fetching yearly solar data:', error);
      } finally {
        setCurrentSolarYearOnly(year);
        setIsYearlySolarLoading(false);
      }
    }
    if (element === 'yearlyHouseMeteringPower') {
      try {
        setIsYearlyMeteringLoading(true);
        const yearlyMeteringResult = await fetchYearlyAccountMeteringData(
          assetName,
          year
        );
        setYearlyMeteringData(yearlyMeteringResult);
      } catch (error) {
        console.error('Error fetching yearly solar data:', error);
      } finally {
        setCurrentMeteringYearOnly(year);
        setIsYearlyMeteringLoading(false);
      }
    }
    if (element === 'yearlyHouseLoadPower') {
      try {
        setIsYearlyLoadLoading(true);
        const yearlyLoadResult = await fetchYearlyAccountLoadData(
          assetName,
          year
        );
        setYearlyLoadData(yearlyLoadResult);
      } catch (error) {
        console.error('Error fetching yearly solar data:', error);
      } finally {
        setCurrentLoadYearOnly(year);
        setIsYearlyLoadLoading(false);
      }
    }
  };

  const monthFilter = async (element, year, month) => {
    if (!element || !year || !month) return;

    const { startOfMonth, endOfMonth } = calculateMonthRangeInUTC(year, month);

    handleScroll();
    if (element === 'monthlySecondWattnodePower') {
      try {
        setIsMonthlySolarLoading(true);
        const monthlyPowerResult = await fetchAccountSolarData(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyPowerData(monthlyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentSolarYear(year);
        setCurrentSolarMonth(month);
        setIsMonthlySolarLoading(false);
      }
    }
    if (element === 'monthlyHouseMeteringPower') {
      try {
        setIsMonthlyMeteringLoading(true);
        const monthlyMeteringResult = await fetchAccountMeteringData(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyMeteringData(monthlyMeteringResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentMeteringYear(year);
        setCurrentMeteringMonth(month);
        setIsMonthlyMeteringLoading(false);
      }
    }
    if (element === 'monthlyHouseLoadPower') {
      try {
        setIsMonthlyLoadLoading(true);
        const monthlyLoadResult = await fetchAccountLoadData(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyLoadData(monthlyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentLoadYear(year);
        setCurrentLoadMonth(month);
        setIsMonthlyLoadLoading(false);
      }
    }
  };

  const fetchUpsIconsData = async () => {
    try {
      const upsData = await getUpsAssetDataService(assetName);
      setUpsIconsData(upsData);
    } catch (error) {
      console.error('Error fetching UPS data:', error);
    } finally {
    }
  };

  const fetchSolarIconsData = async () => {
    try {
      const solarData = await getSolarAssetData(assetName);
      setSolarIconsData(solarData);
    } catch (error) {
      console.error('Error fetching UPS data:', error);
    } finally {
    }
  };

  useEffect(() => {
    fetchUpsIconsData();
    fetchSolarIconsData();
    fetchInitialChartsData();
    fetchInitialTablesData();
    fetchAssetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch initial charts data
  const fetchInitialChartsData = async () => {
    const {
      startToUTC,
      endToUTC,
      startOfDay,
      endOfDay,
      startAssetTime,
      endAssetTime,
    } = calculateAssetTimeRange(assetDifferenceFromUtc);

    const {
      startToUTCWeek,
      endToUTCWeek,
      startOfWeek,
      endOfWeek,
      startAssetTimeWeek,
      endAssetTimeWeek,
    } = calculateWeeklyAssetTimeRange(assetDifferenceFromUtc);

    const { startOfMonth, endOfMonth } = calculateMonthlyAssetTimeRange();

    try {
      const dailyPowerResult = await fetchAccountSolarData(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyPowerData(dailyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setPowerAssetStartDate(startAssetTime);
      setPowerAssetEndDate(endAssetTime);
      setSolarDailyStartDate(startOfDay);
      setSolarDailyEndDate(endOfDay);
      setIsDailySolarLoading(false);
    }
    try {
      const dailyLoadResult = await fetchAccountLoadData(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyLoadData(dailyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoadAssetStartDate(startAssetTime);
      setLoadAssetEndDate(endAssetTime);
      setLoadDailyStartDate(startOfDay);
      setLoadDailyEndDate(endOfDay);
      setIsDailyLoadLoading(false);
    }
    try {
      const dailyMeteringPowerResult = await fetchAccountMeteringData(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyMeteringData(dailyMeteringPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setMeteringAssetStartDate(startAssetTime);
      setMeteringAssetEndDate(endAssetTime);
      setMeteringDailyStartDate(startOfDay);
      setMeteringDailyEndDate(endOfDay);
      setIsDailyMeteringLoading(false);
    }
    try {
      const weeklyMeteringResult = await fetchAccountMeteringData(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyMeteringData(weeklyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setMeteringAssetStartWeekDate(startAssetTimeWeek);
      setMeteringAssetEndWeekDate(endAssetTimeWeek);
      setMeteringWeeklyStartDate(startOfWeek);
      setMeteringWeeklyEndDate(endOfWeek);
      setIsWeeklyMeteringLoading(false);
    }
    try {
      const weeklyLoadResult = await fetchAccountLoadData(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyLoadData(weeklyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoadAssetStartWeekDate(startAssetTimeWeek);
      setLoadAssetEndWeekDate(endAssetTimeWeek);
      setLoadWeeklyStartDate(startOfWeek);
      setLoadWeeklyEndDate(endOfWeek);
      setIsWeeklyLoadLoading(false);
    }
    try {
      const weeklyPowerResult = await fetchAccountSolarData(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyPowerData(weeklyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setPowerAssetStartWeekDate(startAssetTimeWeek);
      setPowerAssetEndWeekDate(endAssetTimeWeek);
      setSolarWeeklyStartDate(startOfWeek);
      setSolarWeeklyEndDate(endOfWeek);
      setIsWeeklySolarLoading(false);
    }
    try {
      const monthlyPowerResult = await fetchAccountSolarData(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyPowerData(monthlyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlySolarLoading(false);
    }
    try {
      const monthlyMeteringResult = await fetchAccountMeteringData(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyMeteringData(monthlyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlyMeteringLoading(false);
    }
    try {
      const monthlyLoadResult = await fetchAccountLoadData(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyLoadData(monthlyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlyLoadLoading(false);
    }
    try {
      const yearlyPowerResult = await fetchYearlyAccountSolarData(
        assetName,
        currentSolarYearOnly
      );
      setYearlyPowerData(yearlyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlySolarLoading(false);
    }
    try {
      const yearlyMeteringResult = await fetchYearlyAccountMeteringData(
        assetName,
        currentMeteringYearOnly
      );
      setYearlyMeteringData(yearlyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlyMeteringLoading(false);
    }

    try {
      const yearlyLoadResult = await fetchYearlyAccountLoadData(
        assetName,
        currentLoadYearOnly
      );
      setYearlyLoadData(yearlyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlyLoadLoading(false);
    }

    try {
      const dpsPerformanceData = await fetchPerformanceDataService(
        assetName,
        startToUTC,
        endToUTC
      );
      setPowerSoc(dpsPerformanceData);
    } catch (error) {
      console.error('Error fetching performance data:', error);
    } finally {
      setPowerSocStartDate(startOfDay);
      setPowerSocEndDate(endOfDay);
      setPowerSocAssetStartDate(startAssetTime);
      setPowerSocAssetEndDate(endAssetTime);
      setIsPowerSocLoading(false);
    }
  };
  const { circle1Color, circle2Color, circle3Color } =
    getGridPowerStatusColors(upsGridPowerStatus);

  // Date filter functionality for tables
  const dateFilterEvents = (element, startDateTimeParam, endDateTimeParam) => {
    if (!element || !startDateTimeParam || !endDateTimeParam) return;

    const validatedDates = validateAndConvertDatesToUTC(
      startDateTimeParam,
      endDateTimeParam,
      assetDifferenceFromUtc
    );

    if (!validatedDates) {
      console.error('Invalid Date objects');
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    handleScroll();

    const bodyRequest = {
      startDatetime: formattedStartDateUTC,
      endDatetime: formattedEndDateUTC,
      assetName: assetName,
    };

    if (element === 'chargingEvents') {
      dispatch(assetRealTimeDataOperations.getChargingEvents({ bodyRequest }));
      setChargeStartDateTime(startDateTimeParam);
      setChargeEndDateTime(endDateTimeParam);
    }

    if (element === 'dischargingEvents') {
      dispatch(
        assetRealTimeDataOperations.getDischargingEvents({ bodyRequest })
      );
      setDischargeStartDateTime(startDateTimeParam);
      setDischargeEndDateTime(endDateTimeParam);
    }
  };

  const toggleCreateChargeEvent = () => {
    setScrollPosition(0);
    setShowAddChargeEvent(!showAddChargeEvent);
  };

  // Toggle Create Discharge Event Modal
  const toggleCreateDischargeEvent = () => {
    setScrollPosition(0);
    setShowAddDischargeEvent(!showAddDischargeEvent);
  };

  // Toggle delete charging event modal
  const toggleDeleteChargingEventModal = () => {
    setScrollPosition(0);
    setDeleteChargingEventModal(!showDeleteChargingEventModal);
  };

  // Toggle delete discharging event modal
  const toggleDeleteDischargingEventModal = () => {
    setScrollPosition(0);
    setDeleteDischargingEventModal(!showDeleteDischargingEventModal);
  };

  // Toggle opt out event modal
  const toggleOptOutEventModal = () => {
    setScrollPosition(0);
    setShowOptOutEventModal(!showOptOutEventModal);
  };

  const fetchAssetData = async () => {
    try {
      const assetDataResponse = await getAssetDataService(assetName);
      const assetDataObject = {
        ...assetDataResponse?.assetDetail[0],
        ...assetDataResponse?.metaData,
      };
      setAssetDetails(assetDataObject);
    } catch (error) {
      console.error('Error fetching asset data:', error);
    } finally {
    }
  };

  const nowAssetTime = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );

  const [currentSolarYearOnly, setCurrentSolarYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentMeteringYearOnly, setCurrentMeteringYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentLoadYearOnly, setCurrentLoadYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentSolarYear, setCurrentSolarYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentSolarMonth, setCurrentSolarMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const [currentMeteringYear, setCurrentMeteringYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentMeteringMonth, setCurrentMeteringMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const [currentLoadYear, setCurrentLoadYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentLoadMonth, setCurrentLoadMonth] = useState(
    nowAssetTime.getMonth() + 1
  );

  const ResponsiveGridLayout = WidthProvider(Responsive);

  return (
    <main className="flex justify-center p-2 md:p-5 min-h-full bg-white">
      <section className="container">
        <div className="p-6 flex flex-col justify-between" ref={modalRef}>
          <div
            className={`flex flex-col md:grid md:grid-cols-5 gap-5 items-center mt-5 mb-5`}
          >
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Grid Power Status
                </h2>
                <div className="h-[125px] w-[110px]">
                  <GridPowerStatus
                    className="relative h-full w-full"
                    style={{
                      '--circle1-color': circle1Color,
                      '--circle2-color': circle2Color,
                      '--circle3-color': circle3Color,
                    }}
                  />
                </div>
                <div>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {upsGridPowerStatus}
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  UPS Input Power
                </h2>
                <div className="h-[125px] w-[110px]">
                  <UpsInputPower />
                </div>
                <div>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {upsInputPower} kW
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  UPS Input Voltage
                </h2>
                <div className="h-[125px] w-[110px]">
                  <UpsInputVoltage />
                </div>
                <div>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {upsInputVoltage} V
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  UPS Input Frequency
                </h2>
                <div className="h-[125px] w-[110px]">
                  <UpsInputHz />
                </div>
                <div>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {upsInputHz} Hz
                  </p>
                </div>
              </div>
            )}
            {assetDetailsData.enableDPS && assetDetailsData.enableBESS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Target Power
                </h2>
                <div className="h-[125px] w-[110px]">
                  <TargetPower className="relative z-20 h-full w-full " />
                </div>
                <div>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {powerGoal} kW
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Battery Throughput
                </h2>
                <div className="h-[125px] w-[110px]">
                  <BatteryThroughput />
                  <div className="absolute left-2 bottom-0 w-[100px] iconFilling" />
                </div>
                <div className={`${assetDetailsData.enableDPS && assetDetailsData.enableBESS 
                  ? "min-h-[50px]" : ''} flex flex-col items-center justify-center`}>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {batteryThroughput} kWh
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Battery State of Charge
                </h2>
                <div className="relative overflow-hidden h-[125px] w-[110px]">
                  <Battery className="relative z-20 h-full w-full" />
                  <div className="absolute left-2 w-[100px] bottom-4 h-[89px]">
                    <div
                      className="absolute w-[100px] iconFilling"
                      style={{ height: `${soc}%`, bottom: 0 }}
                    ></div>
                  </div>
                </div>
                <div className="min-h-[50px] flex flex-col items-center justify-center">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {soc}%
                  </p>
                  <p className="text-center text-sm font-medium sm:text-base">
                    Battery Status: {inverterStatus}
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Battery State of Health
                </h2>
                <div className="relative overflow-hidden h-[125px] w-[110px]">
                  <BatteryDataSoh className="relative z-20 h-full w-full" />
                  <div className="absolute left-2 w-[100px] bottom-4 h-[89px]">
                    <div
                      className="absolute w-[100px] iconFilling"
                      style={{ height: `${soh}%`, bottom: 0 }}
                    ></div>
                  </div>
                </div>
                <div className="min-h-[50px] flex flex-col items-center justify-center">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {soh}%
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Module Temperature
                </h2>
                <div className="relative overflow-hidden h-[125px] w-[110px]">
                  <Thermometer className="relative z-20 h-full w-full" />
                  <div
                    className="absolute left-2 bottom-0 w-[100px] iconFilling"
                    style={{ height: `${moduleTemperatureBar}%` }}
                  />
                </div>
                <div className="min-h-[50px] flex flex-col items-center justify-center">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {moduleTemperatureC}&deg;C
                  </p>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {moduleTemperatureF}&deg;F
                  </p>
                </div>
              </div>
            )}
            {enableUPS && (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Room Temperature
                </h2>
                <div className="h-[125px] w-[110px]">
                  <RoomTemperature />
                </div>
                <div className="min-h-[50px] flex flex-col items-center justify-center">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {roomTemperatureC}&deg;C
                  </p>
                  <p className="text-center text-sm font-medium sm:text-base">
                    {roomTemperatureF}&deg;F
                  </p>
                </div>
              </div>
            )}
          </div>
          {enableSecondary && (
            <div
              className={`flex flex-wrap md:justify-between justify-center items-center gap-6 mt-5 mb-5`}
            >
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  My House
                </h2>
                <div className="h-[150px] w-[150px]">
                  <MyHouse />
                </div>
                <div>
                  <UnderIconBar
                    now={myHouseNow}
                    today={myHouseToday}
                    thisMonth={myHouseThisMonth}
                    tillNow={myHouseTillNow}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  {secondaryName || 'Secondary'} Energy
                </h2>
                <div className="h-[150px] w-[150px]">
                  <SolarEnergy />
                </div>
                <div>
                  <UnderIconBar
                    now={solarEnergyNow}
                    today={solarEnergyToday}
                    thisMonth={solarEnergyThisMonth}
                    tillNow={solarEnergyTillNow}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px]font-medium text-xl">
                  House Metering
                </h2>
                <div className="h-[150px] w-[150px]">
                  <HouseMetering />
                </div>
                <div>
                  <UnderIconBar
                    now={houseMeteringNow}
                    today={houseMeteringToday}
                    thisMonth={houseMeteringThisMonth}
                    tillNow={houseMeteringTillNow}
                  />
                </div>
              </div>
            </div>
          )}
          {enableSecondary && (
            <div
              className={`flex flex-wrap md:justify-between justify-center items-center gap-6 mt-5 px-5 mb-5`}
            >
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  {secondaryName || 'Secondary'} Production Today
                </h2>
                <div className="h-[150px] w-[150px]">
                  <ProductionToday />
                </div>
                <div className="mt-10">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {productionToday} kWh
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  {secondaryName || 'Secondary'} Power Now
                </h2>
                <div className="h-[150px] w-[150px]">
                  <SolarPowerNow />
                </div>
                <div className="mt-10">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {solarPowerNow} kW
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl ">
                  Lifetime Production
                </h2>
                <div className="h-[150px] w-[150px]">
                  <LifetimeProduction />
                </div>
                <div className="mt-10">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {lifetimeProduction} MWh
                  </p>
                </div>
              </div>
            </div>
          )}
          <ResponsiveGridLayout
            className="layout"
            layouts={{ lg: DefaultSingleBessLayout }}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
            rowHeight={30}
            margin={[15, 15]}
            measureBeforeMount={true}
            useCSSTransforms={false}
            draggableHandle=".draggableHandle"
            draggableCancel=".dashboardNav--cancel"
          >
            {DefaultSingleBessLayout?.map(element => {
              const title = idToText(element.i);

              // Inject child components dynamically
              let componentSwitch;
              let filter;
              let widgetDateFilter;
              let widgetDateRange;
              let createEvent;
              let createEventAndFilters;
              let widgetDateTimeFilter;
              let widgetYearFilter;
              let year;
              let month;
              let timezone;
              let dailyFilter;
              let assetDifferenceFromUtcInMinutes;
              let additionalTitle;
              let widgetMonthFilter;
              let isMaxDayWeek;
              switch (element.i) {
                case 'BESSPerformance':
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: powerSocStartDate,
                    end: powerSocEndDate,
                  };

                  componentSwitch = !enableBESS ? null : isPowerSocLoading ? (
                    <div className="w-full h-full flex justify-center items-center">
                      <Loader />
                    </div>
                  ) : (
                    <PowerSoc
                      assetName={assetName}
                      isDpsActive={assetDetailsData.enableDPS}
                      powerSoc={powerSoc}
                      powerSocStartDate={powerSocAssetStartDate}
                      powerSocEndDate={powerSocAssetEndDate}
                      assetTimezone={assetTimezone}
                      assetDifferenceFromUtc={assetDifferenceFromUtc}
                    />
                  );
                  break;
                case 'chargingEvents':
                  createEventAndFilters = true;
                  timezone = assetTimezone;
                  assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;
                  widgetDateTimeFilter = dateFilterEvents;
                  widgetDateRange = {
                    start: chargeStartDateTime,
                    end: chargeEndDateTime,
                  };
                  componentSwitch =
                    !enableBESS ? null : getChargingEventsLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <ChargingEvents
                        toggleDeleteChargingEventModal={
                          toggleDeleteChargingEventModal
                        }
                        setActiveChargingEventID={setActiveChargingEventID}
                        isWidget={true}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                  break;
                case 'scheduledDR':
                  createEvent = true;
                  componentSwitch = <ScheduledEvents />;
                  break;
                case 'dischargingEvents':
                  createEventAndFilters = true;
                  timezone = assetTimezone;
                  assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;
                  widgetDateTimeFilter = dateFilterEvents;
                  widgetDateRange = {
                    start: dischargeStartDateTime,
                    end: dischargeEndDateTime,
                  };
                  componentSwitch =
                    !enableBESS ? null : getDischargingEventsLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <DischargingEvents
                        toggleDeleteDischargingEventModal={
                          toggleDeleteDischargingEventModal
                        }
                        toggleOptOutEventModal={toggleOptOutEventModal}
                        setActiveDischargingEventID={
                          setActiveDischargingEventID
                        }
                        userDetails={userDetails}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                  break;
                case 'yearlySecondWattnodePower':
                  additionalTitle = `Yearly ${secondaryName || 'Secondary'} Energy`;
                  widgetYearFilter = yearFilter;
                  year = currentSolarYearOnly;
                  componentSwitch =
                    !enableSecondary ? null : isYearlySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarYearly
                        year={year}
                        maxPower={yearlyPowerData}
                        assetName={assetName}
                        energyName={secondaryName || 'Secondary'}
                      />
                    );
                  break;
                case 'yearlyHouseMeteringPower':
                  additionalTitle = `Yearly House Metering Energy`;
                  widgetYearFilter = yearFilter;
                  year = currentMeteringYearOnly;
                  componentSwitch =
                    !enableSecondary ? null : isYearlyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarYearly
                        year={year}
                        maxPower={yearlyMeteringData}
                        assetName={assetName}
                        energyName={'House Metering'}
                      />
                    );
                  break;
                case 'yearlyHouseLoadPower':
                  additionalTitle = `Yearly House Load Energy`;
                  widgetYearFilter = yearFilter;
                  year = currentLoadYearOnly;
                  componentSwitch =
                    !enableSecondary ? null : isYearlyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarYearly
                        year={year}
                        maxPower={yearlyLoadData}
                        assetName={assetName}
                        energyName={'House Load'}
                      />
                    );
                  break;
                case 'dailySecondWattnodePower':
                  additionalTitle = `Daily ${secondaryName || 'Secondary'} Power`;
                  dailyFilter = true;
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: solarDailyStartDate,
                    end: solarDailyEndDate,
                  };
                  componentSwitch =
                    !enableSecondary ? null : isDailySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarDaily
                        assetName={assetName}
                        backupLoadStartDate={backupLoadAssetStartDate}
                        backupLoadEndDate={backupLoadAssetEndDate}
                        backupLoad={dailyPowerData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        secondaryName={secondaryName || 'Secondary'}
                      />
                    );
                  break;
                case 'dailyHouseLoadPower':
                  additionalTitle = `Daily House Load Power`;
                  dailyFilter = true;
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: loadDailyStartDate,
                    end: loadDailyEndDate,
                  };
                  componentSwitch =
                    !enableSecondary ? null : isDailyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <LoadDaily
                        assetName={assetName}
                        backupLoadStartDate={loadAssetStartDate}
                        backupLoadEndDate={loadAssetEndDate}
                        backupLoad={dailyLoadData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                  break;
                case 'weeklySecondWattnodePower':
                  additionalTitle = `Weekly ${secondaryName || 'Secondary'} Power`;
                  isMaxDayWeek = true;
                  dailyFilter = true;
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: solarWeeklyStartDate,
                    end: solarWeeklyEndDate,
                  };
                  componentSwitch =
                    !enableSecondary ? null : isWeeklySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarWeekly
                        assetName={assetName}
                        backupLoad={weeklyPowerData}
                        backupLoadStartDate={powerAssetStartWeekDate}
                        backupLoadEndDate={powerAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        secondaryName={secondaryName || 'Secondary'}
                      />
                    );
                  break;

                case 'dailyHouseMeteringPower':
                  dailyFilter = true;
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: meteringDailyStartDate,
                    end: meteringDailyEndDate,
                  };
                  componentSwitch = componentSwitch =
                    !enableSecondary ? null : isDailyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <MeteringDaily
                        assetName={assetName}
                        backupLoadStartDate={meteringAssetStartDate}
                        backupLoadEndDate={meteringAssetEndDate}
                        backupLoad={dailyMeteringData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                  break;

                case 'weeklyHouseMeteringPower':
                  additionalTitle = `Weekly House Metering Power`;
                  dailyFilter = true;
                  isMaxDayWeek = true;
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: meteringWeeklyStartDate,
                    end: meteringWeeklyEndDate,
                  };
                  componentSwitch =
                    !enableSecondary ? null : isWeeklyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <MeteringWeekly
                        assetName={assetName}
                        backupLoad={weeklyMeteringData}
                        backupLoadStartDate={meteringAssetStartWeekDate}
                        backupLoadEndDate={meteringAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                  break;

                case 'weeklyHouseLoadPower':
                  additionalTitle = `Weekly House Load Power`;
                  dailyFilter = true;
                  isMaxDayWeek = true;
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: loadWeeklyStartDate,
                    end: loadWeeklyEndDate,
                  };
                  componentSwitch =
                    !enableSecondary ? null : isWeeklyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <LoadWeekly
                        assetName={assetName}
                        backupLoad={weeklyLoadData}
                        backupLoadStartDate={loadAssetStartWeekDate}
                        backupLoadEndDate={loadAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                  break;

                case 'monthlySecondWattnodePower':
                  additionalTitle = `Monthly ${secondaryName || 'Secondary'} Energy`;
                  year = currentSolarYear;
                  month = currentSolarMonth;
                  widgetMonthFilter = monthFilter;
                  componentSwitch =
                    !enableSecondary ? null : IsMonthlyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarMonthly
                        powerMonthly={monthlyPowerData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={secondaryName || 'Secondary'}
                      />
                    );
                  break;
                case 'monthlyHouseMeteringPower':
                  additionalTitle = `Monthly House Metering Energy`;
                  year = currentMeteringYear;
                  month = currentMeteringMonth;
                  widgetMonthFilter = monthFilter;
                  componentSwitch =
                    !enableSecondary ? null : IsMonthlySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarMonthly
                        powerMonthly={monthlyMeteringData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={'House Metering'}
                      />
                    );
                  break;
                case 'monthlyHouseLoadPower':
                  additionalTitle = `Monthly House Load Energy`;
                  year = currentLoadYear;
                  month = currentLoadMonth;
                  widgetMonthFilter = monthFilter;
                  componentSwitch =
                    !enableSecondary ? null : IsMonthlyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarMonthly
                        powerMonthly={monthlyLoadData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={'House Load'}
                      />
                    );
                  break;
                default:
                  return null;
              }

              if (componentSwitch === null) return null;
              return (
                <div
                  key={element.i}
                  className="container-border grid-component__container overflow-hidden"
                >
                  <Widget
                    title={additionalTitle ? additionalTitle : title}
                    id={element.i}
                    hasFilter={filter}
                    dateFilter={widgetDateFilter}
                    dateTimeFilter={widgetDateTimeFilter}
                    yearFilter={widgetYearFilter}
                    monthFilter={widgetMonthFilter}
                    dateRange={widgetDateRange}
                    year={year}
                    month={month}
                    isMaxDayWeek={isMaxDayWeek}
                    dailyFilter={dailyFilter}
                    child={componentSwitch}
                    hasCreateEvent={createEvent}
                    hasCreateEventAndFilters={createEventAndFilters}
                    toggleCreateChargeEvent={toggleCreateChargeEvent}
                    toggleCreateDischargeEvent={toggleCreateDischargeEvent}
                    assetTimezone={timezone}
                    assetDifferenceFromUtc={assetDifferenceFromUtcInMinutes}
                    assetName={assetName}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
        {!!showAddChargeEvent && (
          <Modal
            toggleModal={toggleCreateChargeEvent}
            isSmall={true}
            child={
              <CreateChargeEvent
                assetName={assetName}
                toggleModal={toggleCreateChargeEvent}
                userDetails={userDetails}
                dateFilterEvents={dateFilterEvents}
                chargeStartDateTime={chargeStartDateTime}
                chargeEndDateTime={chargeEndDateTime}
                toggleDeleteChargingEventModal={toggleDeleteChargingEventModal}
                setActiveChargingEventID={setActiveChargingEventID}
                enableDPS={assetDetailsData.enableDPS}
                assetDifferenceFromUtc={assetDifferenceFromUtc}
              />
            }
          />
        )}

        {!!showAddDischargeEvent && (
          <Modal
            toggleModal={toggleCreateDischargeEvent}
            isSmall={true}
            child={
              <CreateDischargeEvent
                assetName={assetName}
                toggleModal={toggleCreateDischargeEvent}
                userDetails={userDetails}
                dateFilterEvents={dateFilterEvents}
                dischargeStartDateTime={dischargeStartDateTime}
                dischargeEndDateTime={dischargeEndDateTime}
                assetDifferenceFromUtc={assetDifferenceFromUtc}
              />
            }
          />
        )}

        {!!showDeleteChargingEventModal && (
          <Modal
            toggleModal={toggleDeleteChargingEventModal}
            isSmall={true}
            child={
              <DeleteChargingEvent
                activeChargingEventID={activeChargingEventID}
                toggleModal={toggleDeleteChargingEventModal}
                dateFilterEvents={dateFilterEvents}
                chargeStartDateTime={chargeStartDateTime}
                chargeEndDateTime={chargeEndDateTime}
                assetName={assetName}
              />
            }
          />
        )}

        {!!showDeleteDischargingEventModal && (
          <Modal
            toggleModal={toggleDeleteDischargingEventModal}
            isSmall={true}
            child={
              <DeleteDischargingEvent
                activeDischargingEventID={activeDischargingEventID}
                toggleModal={toggleDeleteDischargingEventModal}
                dateFilterEvents={dateFilterEvents}
                dischargeStartDateTime={dischargeStartDateTime}
                dischargeEndDateTime={dischargeEndDateTime}
                assetName={assetName}
              />
            }
          />
        )}

        {!!showOptOutEventModal && (
          <Modal
            toggleModal={toggleOptOutEventModal}
            isSmall={true}
            child={
              <OptOutDischargingEvent
                activeDischargingEventID={activeDischargingEventID}
                toggleModal={toggleOptOutEventModal}
                dateFilterEvents={dateFilterEvents}
                dischargeStartDateTime={dischargeStartDateTime}
                dischargeEndDateTime={dischargeEndDateTime}
                assetName={assetName}
              />
            }
          />
        )}
      </section>
    </main>
  );
};

export default SingleBESSContainer;
