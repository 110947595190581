import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from 'recharts';
import { IconContext } from 'react-icons';
import { PiFileCsv, PiFilePng } from "react-icons/pi";
import { getTicks, convertToDays, tickFormatterToAssetTime } from '../../helpers/charts';
import { roundToNearestTwoDecimal } from '../../helpers/charts/roundToDecimal';
import formatRangeForFileName from '../../helpers/formatDate/formatRangeForFileName';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { useEffect, useState, useCallback } from 'react';
import api from '../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';
import { CSVLink } from "react-csv";
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

function MeteringDaily(
  {
    backupLoadStartDate,
    backupLoadEndDate,
    backupLoad = [],
    assetName,
    assetTimezone,
    assetDifferenceFromUtc,
  }
) {
  const [backupLoadData, setBackupLoadData] = useState(backupLoad);
  const [getPng, { ref }] = useCurrentPng();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);

      const assetDate = new Date(date.getTime() - (- date.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

      const formattedDate = `${assetDate.getFullYear()}-`
        + `${String(assetDate.getMonth() + 1).padStart(2, '0')}-`
        + `${String(assetDate.getDate()).padStart(2, '0')} `
        + `${String(assetDate.getHours()).padStart(2, '0')}:`
        + `${String(assetDate.getMinutes()).padStart(2, '0')} `;

      return (
        <div className='custom-tooltip' style={{ backgroundColor: '#f4f4f4', padding: '10px', border: '1px solid #ccc' }}>
          <p className='label'>{`Timezone: ${assetTimezone}`}</p>
          <p className='label'>{`Time: ${formattedDate}`}</p>
          <p className='intro'>{`Power: ${parseFloat(payload[0].value).toFixed(2)} kW`}</p>
        </div>
      );
    }
    return null;
  };

  // Format the start and end dates as ISO 8601 strings
  const startDate = backupLoadStartDate.toISOString();
  const endDate = backupLoadEndDate.toISOString();

  const dateDifference = backupLoadEndDate - backupLoadStartDate;
  const dateDifferenceInDays = convertToDays(dateDifference);
  const xTicks = getTicks(backupLoadData, dateDifferenceInDays);
  const username = localStorage.getItem('cdnzUser');

  useEffect(() => {
    const fetchNewBackupLoadData = () => {
      const token = localStorage.getItem('cdnzAccessToken');
      api.post(
        `/device/house-metering/daily`,
        {
          startDate,
          endDate,
          assetName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        },
      )
        .then((res) => {
          setBackupLoadData(res.data.dailyHouseMeteringPowerData);
        })
        .catch(() => {
          Notify.warning('Failed to fetch house metering daily data', notifyOptions);
        });
    };
    const intervalId = setInterval(fetchNewBackupLoadData, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dummyData = [];

  if (!backupLoadData || !Array.isArray(backupLoadData) || backupLoadData.length === 0) {
    let endTime;
    const currentDate = new Date();
    const currentDay = currentDate.toDateString();

    const endDate = new Date(backupLoadEndDate.getTime() - (- backupLoadEndDate.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

    const loadEndDateString = new Date(endDate).toDateString();

    if (loadEndDateString === currentDay) {
      endTime = new Date();
    } else {
      endTime = new Date(endDate);
      endTime.setHours(23, 59, 59, 999);
    }

    // Calculate the number of half-hour segments between backupLoadStartDate and endTime
    const startTime = new Date(backupLoadStartDate);
    const segmentCount = Math.ceil((endTime - startTime) / (30 * 60 * 1000));

    // Create an array of objects with timestamps for each half-hour segment
    for (let i = 0; i < segmentCount; i++) {
      const timestamp = new Date(startTime.getTime() + i * 30 * 60 * 1000);
      dummyData.push({
        timestamp,
        secondWattnodePower: 0,
      });
    }
  }

  const showData =
    (!backupLoadData || !Array.isArray(backupLoadData) || backupLoadData.length === 0)
      ? dummyData
      : backupLoadData;

  const rangeForFileName = formatRangeForFileName(backupLoadStartDate, backupLoadEndDate, assetDifferenceFromUtc);

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, `${assetName} house metering daily ${rangeForFileName}.png`);
    }
  }, [getPng, assetName, rangeForFileName]);

  const transformedData = transformDataForCSV(showData, assetDifferenceFromUtc, ['houseMeteringPower']);

  return (
    <>
      {(
        <ResponsiveContainer>
          <AreaChart
            ref={ref}
            width={500}
            height={300}
            data={showData}
            margin={{
              top: 5,
              right: 30,
              left: 35,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#0f3052' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#0f3052' stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='timestamp'
              ticks={xTicks}
              tickFormatter={(timestamp) => tickFormatterToAssetTime(timestamp, dateDifference, assetDifferenceFromUtc)}
            />
            <YAxis
              label={{
                value: 'kW',
                position: 'center',
                dx: -35,
                angle: -90
              }}
              tickFormatter={(value) => roundToNearestTwoDecimal(value)}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type='monotone'
              dataKey="houseMeteringPower"
              stroke='#0f3052'
              fillOpacity={1}
              fill='url(#colorUv)'
              isAnimationActive={false}
              animateNewValues={true}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CSVLink
          data={transformedData}
          filename={`${assetName} house metering daily ${rangeForFileName}.csv`}
          className='csv-link'
        >
          <button
            type="button"
            className='save-as-csv rounded-md px-1 py-1 text-xs'
            title="Save as CSV"
          >
            <IconContext.Provider value={{ color: '#00000' }}>
              <PiFileCsv size={24} />
            </IconContext.Provider>
          </button>
        </CSVLink>
        <button
          type="button"
          className='save-as-png rounded-md px-1 py-1 text-xs'
          onClick={handleDownload}
          title="Save as PNG"
        >
          <IconContext.Provider value={{ color: '#00000' }}>
            <PiFilePng size={24} />
          </IconContext.Provider>
        </button>
      </div>
    </>
  );
}

export default MeteringDaily;
