import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { saveAccessTokenLifetime } from '../utils/tokenRefreshing';
import Loader from '../components/Loader';

/**
 * @TODO: Fix issue with Cadenza dec
 * Hardcoded due to a problem with the .env environment in dev Cadenza
 * */
const baseURL =
  process.env.REACT_APP_BASE_URL ||
  `https://mycdzcloud-nodeapp-dev.azurewebsites.net/api`;

const Verify = () => {
  // Set Auth initial state
  const { setAuth } = useAuth();
  // grabs the token from the url
  const token = new URLSearchParams(window.location.search).get('token');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // useEffect hook to make a http request to verify the token
  useEffect(() => {
    axios
      .get(`${baseURL}/authentication/login-with-token?token=${token}`)
      .then(res => {
        if (res.status === 200) {
          // set cdnzAuth to true in localStorage so other windows can redirect
          const user = res.data.user;
          const jwt = res.data.tokens.accessToken;
          const role = res.data.user?.subType;
          const accountType = res.data.user?.accountType;

          localStorage.setItem('cdnzAccessToken', res.data.tokens.accessToken);
          localStorage.setItem(
            'cdnzRefreshToken',
            res.data.tokens.refreshToken
          );
          localStorage.setItem('cdnzUser', res.data.user?.userName);
          localStorage.setItem('cdnzRole', res.data.user?.subType);
          localStorage.setItem('cdnzAccountType', res.data.user?.accountType);
          saveAccessTokenLifetime();
          /* ********
              REMOVE LOCALSTORAGE USAGE
            **********/
          setAuth({ user, jwt, role, accountType });
          // navigate to dashboard
          // navigate("/");
          return (window.location.href = '/');
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true));

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate]);

  if (error) {
    return <p>Verification failed.</p>;
  }

  return (
    <div className="loader-global-container">
      <Loader classNames="w-32 h-32" />
    </div>
  );
};

export default Verify;
