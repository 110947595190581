import { useCallback } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { IconContext } from 'react-icons';
import { PiFileCsv, PiFilePng } from 'react-icons/pi';
import { CSVLink } from 'react-csv';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'long',
    }).format(date);

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#f4f4f4',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p className="label">{`Month: ${formattedDate}`}</p>
        <p className="intro">{`Energy: ${parseFloat(payload[0].value).toFixed(2)} MWh`}</p>
      </div>
    );
  }
  return null;
};

const SolarYearly = ({
  maxPower,
  year,
  assetName,
  energyName,
}) => {
  const [getPng, { ref }] = useCurrentPng();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let dummyData = [];

  if (!maxPower || !Array.isArray(maxPower) || maxPower.length === 0) {
    months.forEach(month => {
      dummyData.push({
        month: `${month}-${year}`,
        power: 0,
      });
    });
  }

  const maxPowerInOrder = maxPower.sort(
    (a, b) =>
      months.indexOf(a.month.split('-')[0]) -
      months.indexOf(b.month.split('-')[0])
  );

  const showData =
    !maxPower || !Array.isArray(maxPower) || maxPower.length === 0
      ? dummyData
      : maxPowerInOrder;

  const csvData = showData.map(item => ({
    month: item.month,
    energy: item.power,
  }));

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, `${assetName} ${energyName} yearly ${year}.png`);
    }
  }, [getPng, assetName, year, energyName]);

  return (
    <>
      {
        <ResponsiveContainer>
          <BarChart
            ref={ref}
            width={500}
            height={300}
            data={showData}
            barGap={2}
            barSize={8}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0f3052" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0f3052" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              tickFormatter={timestamp => {
                const date = new Date(timestamp);
                return new Intl.DateTimeFormat('en-US', {
                  month: 'short',
                }).format(date);
              }}
            />
            <YAxis
              label={{
                value: 'MWh',
                position: 'center',
                dx: -30,
                angle: -90,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar radius={[8, 8, 0, 0]} dataKey="power" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      }
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CSVLink
          data={csvData}
          filename={`${assetName} ${energyName} yearly ${year}.csv`}
          className="csv-link"
        >
          <button
            type="button"
            className="save-as-csv rounded-md px-1 py-1 text-xs"
            title="Save as CSV"
          >
            <IconContext.Provider value={{ color: '#00000' }}>
              <PiFileCsv size={24} />
            </IconContext.Provider>
          </button>
        </CSVLink>
        <button
          type="button"
          className="save-as-png rounded-md px-1 py-1 text-xs"
          onClick={handleDownload}
          title="Save as PNG"
        >
          <IconContext.Provider value={{ color: '#00000' }}>
            <PiFilePng size={24} />
          </IconContext.Provider>
        </button>
      </div>
    </>
  );
};

export default SolarYearly;
