// React
import React, { useEffect, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { CSVLink } from 'react-csv';

// Services
import { getAssetData as getAssetDataService } from '../../services/assetService';
import { fetchBessLayout as fetchBessLayoutService } from '../../services/layoutService';
import {
  fetchChartHistoricalData as fetchChartHistoricalDataService,
  fetchPerformanceData as fetchPerformanceDataService,
  fetchMaxPowerData as fetchMaxPowerDataService,
} from '../../services/graphService';
import { fetchBessHistoricalData as fetchBessHistoricalDataService } from '../../services/historicalDataService';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import assetRealTimeDataOperations from '../../redux/assetRealTimeData/assetRealTimeDataOperations';
import { assetRealTimeDataSelectors } from '../../redux/assetRealTimeData/assetRealTimeDataSelectors';
import userOperations from '../../redux/user/userOperations';
import { userSelectors } from '../../redux/user/userSelectors';

// Utils
import prepLayout from '../../utils/PrepLayout';

// Helpers
import { idToText } from '../../helpers/widget/idToText';
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import getTimezoneFromCoordinates from '../../helpers/formatDate/getTimezoneFromCoordinates';
import formatRangeForFileNameLocalTime from '../../helpers/formatDate/formatRangeForFileNameLocalTime';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { validateAndConvertDatesToUTC } from '../../helpers/formatDate/validateAndConvertDatesToUTC';
import {
  calculateAssetTimeRange,
  calculateDateRangeInUTC,
  calculateTableTimeRanges,
} from '../../helpers/formatDate/calculateAssetTimeRange';
import { formatValue } from '../../helpers/icons/formatValue';
import accessControl from '../../helpers/accessControl';

// Components
import { ReactComponent as Battery } from '../../assets/battery_shape.svg';
import { ReactComponent as Thermometer } from '../../assets/thermometer_shape.svg';
import { ReactComponent as ThermometerSun } from '../../assets/thermometer_ambient_shape.svg';
import { ReactComponent as InverterOperation } from '../../assets/inverter_operation.svg';
import { ReactComponent as LoadPower } from '../../assets/battery-plug.svg';
import { ReactComponent as TargetPower } from '../../assets/charging-target.svg';
import { ReactComponent as BatteryThroughput } from '../../assets/charging-time.svg';
import ChargingEvents from '../widgets/ChargingEvents';
import DischargingEvents from '../widgets/DischargingEvents';
import GridPower from '../widgets/GridPower';
import VoltageCurrent from '../widgets/VoltageCurrent';
import PowerSoc from '../widgets/PowerSoc';
import MaxPower from '../widgets/MaxPower';
import DeleteChargingEvent from './chargingEvents/DeleteChargingEvent';
import DeleteDischargingEvent from './dischargingEvents/DeleteDischargingEvent';
import OptOutDischargingEvent from './dischargingEvents/OptOutDischargingEvent';
import Modal from './Modal';
import Loader from '../Loader';
import CreateDischargeEvent from './dischargingEvents/CreateDischargeEvent';
import CreateChargeEvent from './chargingEvents/CreateChargeEvent';
import Widget from '../widgets/Widget';

// Calendar
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';

// Icons
import { FiX, FiChevronDown } from 'react-icons/fi';
import { PiFileCsv } from 'react-icons/pi';
import { IconContext } from 'react-icons';
import { BsCalendarEvent } from 'react-icons/bs';


const BessDashboard = ({
  assetData: assetDetailsData = {},
  toggleAssetDetails,
  toggleAssetUpsDetails,
  toggleAssetSecondaryDetails,
}) => {
  const {
    name: assetName = '',
    enableUPS,
    enableSecondary,
    secondaryName,
  } = assetDetailsData;

  const dispatch = useDispatch();

  const modalRef = useRef();

  // Init responsive grid layout
  const ResponsiveGridLayout = WidthProvider(Responsive);

  // Asset difference from UTC in minutes and asset timezone name
  const assetDifferenceFromUtc = getUtcOffsetDifference(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );
  const assetTimezone = getTimezoneFromCoordinates(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );

  const now = new Date();
  const nowAssetTime = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  const assetTimeEndOfToday = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  ).setHours(23, 59, 59, 999);

  const [layout, setLayout] = useState(null);

  const [isAssetDataLoading, setIsAssetDataLoading] = useState(true);
  const [isGridPowerLoading, setIsGridPowerLoading] = useState(true);
  const [isBatteryVoltageCurrentLoading, setIsBatteryVoltageCurrentLoading] =
    useState(true);
  const [isPowerSocLoading, setIsPowerSocLoading] = useState(true);
  const [isMaxPowerLoading, setIsMaxPowerLoading] = useState(true);
  const [isAssetHistoricalDataLoading, setIsAssetHistoricalDataLoading] =
    useState(false);
  const getChargingEventsLoading = useSelector(
    assetRealTimeDataSelectors.getChargingEventsLoading
  );
  const getDischargingEventsLoading = useSelector(
    assetRealTimeDataSelectors.getDischargingEventsLoading
  );

  const [showAddChargeEvent, setShowAddChargeEvent] = useState(false);
  const [showAddDischargeEvent, setShowAddDischargeEvent] = useState(false);
  const [showDeleteChargingEventModal, setDeleteChargingEventModal] =
    useState(false);
  const [showDeleteDischargingEventModal, setDeleteDischargingEventModal] =
    useState(false);
  const [showOptOutEventModal, setShowOptOutEventModal] = useState(false);
  const [isShowExportAssetData, setIsShowExportAssetData] = useState(false);

  const [activeChargingEventID, setActiveChargingEventID] = useState(null);
  const [activeDischargingEventID, setActiveDischargingEventID] =
    useState(null);

  const [chargeStartDateTime, setChargeStartDateTime] = useState('');
  const [chargeEndDateTime, setChargeEndDateTime] = useState('');
  const [dischargeStartDateTime, setDischargeStartDateTime] = useState('');
  const [dischargeEndDateTime, setDischargeEndDateTime] = useState('');
  const [powerStartDate, setPowerStartDate] = useState('');
  const [powerEndDate, setPowerEndDate] = useState('');
  const [batteryStartDate, setBatteryStartDate] = useState('');
  const [batteryEndDate, setBatteryEndDate] = useState('');
  const [powerSocStartDate, setPowerSocStartDate] = useState('');
  const [powerSocEndDate, setPowerSocEndDate] = useState('');
  const [powerAssetStartDate, setPowerAssetStartDate] = useState('');
  const [powerAssetEndDate, setPowerAssetEndDate] = useState('');
  const [batteryAssetStartDate, setBatteryAssetStartDate] = useState('');
  const [batteryAssetEndDate, setBatteryAssetEndDate] = useState('');
  const [powerSocAssetStartDate, setPowerSocAssetStartDate] = useState('');
  const [powerSocAssetEndDate, setPowerSocAssetEndDate] = useState('');
  const [currentYear, setCurrentYear] = useState(nowAssetTime.getFullYear());
  const [assetDataStartDateTime, setAssetDataStartDateTime] = useState('');
  const [assetDataEndDateTime, setAssetDataEndDateTime] = useState('');

  const [assetDetails, setAssetDetails] = useState({});
  const [gridPower, setGridPower] = useState([]);
  const [batteryVoltageCurrent, setBatteryVoltageCurrent] = useState([]);
  const [powerSoc, setPowerSoc] = useState([]);
  const [maxPower, setMaxPower] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const userDetails = useSelector(userSelectors.getUserDetailsData);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    fetchAssetData();
    fetchLayoutData();
    fetchInitialChartsData();
    fetchInitialTablesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      modalRef.current?.scrollTo(0, scrollPosition);
    }, 0);
  };

  useEffect(() => {
    scrollToPreviousPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToPreviousPosition]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(userOperations.getUserDetails());
  }, [dispatch]);

  // Function to handle scroll position change
  const handleScroll = () => {
    const position = modalRef.current.scrollTop;
    setScrollPosition(position);
  };

  // Toggle Create Charge Event Modal
  const toggleCreateChargeEvent = () => {
    setScrollPosition(0);
    setShowAddChargeEvent(!showAddChargeEvent);
  };

  // Toggle Create Discharge Event Modal
  const toggleCreateDischargeEvent = () => {
    setScrollPosition(0);
    setShowAddDischargeEvent(!showAddDischargeEvent);
  };

  // Toggle delete charging event modal
  const toggleDeleteChargingEventModal = () => {
    setScrollPosition(0);
    setDeleteChargingEventModal(!showDeleteChargingEventModal);
  };

  // Toggle delete discharging event modal
  const toggleDeleteDischargingEventModal = () => {
    setScrollPosition(0);
    setDeleteDischargingEventModal(!showDeleteDischargingEventModal);
  };

  // Toggle opt out event modal
  const toggleOptOutEventModal = () => {
    setScrollPosition(0);
    setShowOptOutEventModal(!showOptOutEventModal);
  };

  // Toggle show export asset data
  const toggleShowExportAssetData = () => {
    setScrollPosition(0);
    setIsShowExportAssetData(!isShowExportAssetData);
  };

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(
        layouts,
        layout,
        `${accessControl.isPlatformAdministrator()
          ? 'bessDashboardLayoutPA'
          : 'bessDashboardLayout'
        }`
      );
    }
  };

  // Fetch asset data
  const fetchAssetData = async () => {
    try {
      const assetDataResponse = await getAssetDataService(assetName);
      const assetDataObject = {
        ...assetDataResponse?.assetDetail[0],
        ...assetDataResponse?.metaData,
      };
      setAssetDetails(assetDataObject);
    } catch (error) {
      console.error('Error fetching asset data:', error);
    } finally {
      setIsAssetDataLoading(false);
    }
  };

  // Fetch layout
  const fetchLayoutData = async () => {
    try {
      const bessLayout = await fetchBessLayoutService(
        accessControl.isPlatformAdministrator()
      );
      setLayout(bessLayout);
    } catch (error) {
      console.error('Error fetching BESS layout:', error);
    }
  };

  // Fetch initial charts data
  const fetchInitialChartsData = async () => {
    const {
      startToUTC,
      endToUTC,
      startOfDay,
      endOfDay,
      startAssetTime,
      endAssetTime,
    } = calculateAssetTimeRange(assetDifferenceFromUtc);

    try {
      const [
        gridPowerResult,
        batteryVoltageResult,
        performanceResult,
        maxPowerResult,
      ] = await Promise.allSettled([
        fetchChartHistoricalDataService(
          assetName,
          startToUTC,
          endToUTC,
          'gridPower'
        ),
        fetchChartHistoricalDataService(
          assetName,
          startToUTC,
          endToUTC,
          'batteryVoltage'
        ),
        fetchPerformanceDataService(assetName, startToUTC, endToUTC),
        fetchMaxPowerDataService(assetName, currentYear),
      ]);

      if (
        gridPowerResult.status === 'fulfilled' &&
        gridPowerResult?.value?.gridPower
      ) {
        setGridPower(gridPowerResult.value.gridPower);
      } else if (gridPowerResult.status !== 'fulfilled') {
        console.error(
          'Error fetching grid power data:',
          gridPowerResult.reason
        );
      }

      if (
        batteryVoltageResult.status === 'fulfilled' &&
        batteryVoltageResult?.value?.batteryVoltage
      ) {
        setBatteryVoltageCurrent(batteryVoltageResult.value.batteryVoltage);
      } else {
        console.error(
          'Error fetching battery voltage/current data:',
          batteryVoltageResult.reason
        );
      }

      if (
        performanceResult.status === 'fulfilled' &&
        performanceResult?.value
      ) {
        setPowerSoc(performanceResult.value);
      } else {
        console.error(
          'Error fetching performance data:',
          performanceResult.reason
        );
      }

      if (maxPowerResult.status === 'fulfilled' && maxPowerResult?.value) {
        setMaxPower(maxPowerResult.value);
      } else {
        console.error('Error fetching max power data:', maxPowerResult.reason);
      }
    } catch (error) {
      console.error('Unexpected error fetching initial charts data:', error);
    } finally {
      setIsGridPowerLoading(false);
      setIsBatteryVoltageCurrentLoading(false);
      setIsPowerSocLoading(false);
      setIsMaxPowerLoading(false);
      setPowerStartDate(startOfDay);
      setPowerEndDate(endOfDay);
      setPowerAssetStartDate(startAssetTime);
      setPowerAssetEndDate(endAssetTime);
      setBatteryStartDate(startOfDay);
      setBatteryEndDate(endOfDay);
      setBatteryAssetStartDate(startAssetTime);
      setBatteryAssetEndDate(endAssetTime);
      setPowerSocStartDate(startOfDay);
      setPowerSocEndDate(endOfDay);
      setPowerSocAssetStartDate(startAssetTime);
      setPowerSocAssetEndDate(endAssetTime);
    }
  };

  // Fetch initial tables data
  const fetchInitialTablesData = () => {
    const { startDate, endDate, startOfDay, endOfNext7Days } =
      calculateTableTimeRanges(assetDifferenceFromUtc);

    const bodyRequest = {
      startDatetime: startDate,
      endDatetime: endDate,
      assetName: assetName,
    };

    // Fetch data for ChargingEvents
    dispatch(assetRealTimeDataOperations.getChargingEvents({ bodyRequest }));
    setChargeStartDateTime(startOfDay);
    setChargeEndDateTime(endOfNext7Days);

    // Fetch data for DischargingEvents
    dispatch(assetRealTimeDataOperations.getDischargingEvents({ bodyRequest }));
    setDischargeStartDateTime(startOfDay);
    setDischargeEndDateTime(endOfNext7Days);
  };

  // Date filter functionality for charts
  const dateFilter = async (element, startDateParam, endDateParam) => {
    if (!element || !startDateParam || !endDateParam) return;

    const { startToUTC, endToUTC, startOfDayUTC, endOfDayUTC } =
      calculateDateRangeInUTC(
        startDateParam,
        endDateParam,
        assetDifferenceFromUtc
      );

    handleScroll();

    if (element === 'gridPower') {
      try {
        setIsGridPowerLoading(true);
        const assetHistoricalData = await fetchChartHistoricalDataService(
          assetName,
          startToUTC,
          endToUTC,
          'gridPower'
        );
        setGridPower(assetHistoricalData.gridPower);
      } catch (error) {
        console.error('Error fetching grid power data:', error);
      } finally {
        setPowerStartDate(startDateParam);
        setPowerEndDate(endDateParam);
        setPowerAssetStartDate(startOfDayUTC);
        setPowerAssetEndDate(endOfDayUTC);
        setIsGridPowerLoading(false);
      }
    }

    if (element === 'batteryVoltage') {
      try {
        setIsBatteryVoltageCurrentLoading(true);
        const assetHistoricalData = await fetchChartHistoricalDataService(
          assetName,
          startToUTC,
          endToUTC,
          'batteryVoltage'
        );
        setBatteryVoltageCurrent(assetHistoricalData.batteryVoltage);
      } catch (error) {
        console.error('Error fetching battery voltage/current data:', error);
      } finally {
        setBatteryStartDate(startDateParam);
        setBatteryEndDate(endDateParam);
        setBatteryAssetStartDate(startOfDayUTC);
        setBatteryAssetEndDate(endOfDayUTC);
        setIsBatteryVoltageCurrentLoading(false);
      }
    }

    if (element === 'peakShaving') {
      try {
        setIsPowerSocLoading(true);
        const dpsPerformanceData = await fetchPerformanceDataService(
          assetName,
          startToUTC,
          endToUTC
        );
        setPowerSoc(dpsPerformanceData);
      } catch (error) {
        console.error('Error fetching performance data:', error);
      } finally {
        setPowerSocStartDate(startDateParam);
        setPowerSocEndDate(endDateParam);
        setPowerSocAssetStartDate(startOfDayUTC);
        setPowerSocAssetEndDate(endOfDayUTC);
        setIsPowerSocLoading(false);
      }
    }
  };

  // Date filter functionality for tables
  const dateFilterEvents = (element, startDateTimeParam, endDateTimeParam) => {
    if (!element || !startDateTimeParam || !endDateTimeParam) return;

    const validatedDates = validateAndConvertDatesToUTC(
      startDateTimeParam,
      endDateTimeParam,
      assetDifferenceFromUtc
    );

    if (!validatedDates) {
      console.error('Invalid Date objects');
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    handleScroll();

    const bodyRequest = {
      startDatetime: formattedStartDateUTC,
      endDatetime: formattedEndDateUTC,
      assetName: assetName,
    };

    if (element === 'chargingEvents') {
      dispatch(assetRealTimeDataOperations.getChargingEvents({ bodyRequest }));
      setChargeStartDateTime(startDateTimeParam);
      setChargeEndDateTime(endDateTimeParam);
    }

    if (element === 'dischargingEvents') {
      dispatch(
        assetRealTimeDataOperations.getDischargingEvents({ bodyRequest })
      );
      setDischargeStartDateTime(startDateTimeParam);
      setDischargeEndDateTime(endDateTimeParam);
    }
  };

  // Year filter functionality for max power chart
  const yearFilter = async (element, year) => {
    if (!element || !year) return;

    handleScroll();

    // Fetch data for Max Power
    try {
      setIsMaxPowerLoading(true);
      const maxPowerData = await fetchMaxPowerDataService(assetName, year);
      setMaxPower(maxPowerData);
    } catch (error) {
      console.error('Error fetching max power data:', error);
    } finally {
      setCurrentYear(year);
      setIsMaxPowerLoading(false);
    }
  };

  // Date filter functionality for asset historical data
  const getHistoricalData = async () => {
    const validatedDates = validateAndConvertDatesToUTC(
      assetDataStartDateTime,
      assetDataEndDateTime,
      assetDifferenceFromUtc
    );

    if (!validatedDates) {
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    try {
      setIsAssetHistoricalDataLoading(true);
      const historicalData = await fetchBessHistoricalDataService(
        assetName,
        formattedStartDateUTC,
        formattedEndDateUTC
      );
      setAssetData(historicalData);
    } catch (error) {
      console.error('Error fetching BESS historical data:', error);
    } finally {
      setIsAssetHistoricalDataLoading(false);
    }
  };

  const rangeForFileName = formatRangeForFileNameLocalTime(
    new Date(assetDataStartDateTime),
    new Date(assetDataEndDateTime)
  );

  const transformedData = transformDataForCSV(
    assetData,
    assetDifferenceFromUtc,
    [
      'InverterACLoadPower',
      'SOC',
      'InverterBatteryLifetime',
      'InverterOperationStatus',
      'InverterChargerStatus',
      'BMSCellMaxTemperatureC',
      'BMSCellMaxTemperatureF',
      'InverterAmbientTemperatureC',
      'InverterAmbientTemperatureF',
    ]
  );

  const handleStartDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataStartDateTime(date.toString());
  };

  const handleEndDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataEndDateTime(date.toString());
  };

  const handleCalendarClose = () => {
    getHistoricalData();
  };

  const life = formatValue(assetDetails?.batterySOC);
  const tempC = formatValue(assetDetails?.batteryTemperatureC, true);
  const tempF = formatValue(assetDetails?.batteryTemperatureF, true);
  const tempBar = tempC >= 50 ? 100 : Math.round(tempC * 2 + 15) || 'N/A';

  const aTempC = formatValue(assetDetails?.ambientTemperatureC, true);
  const aTempF = formatValue(assetDetails?.ambientTemperatureF, true);
  const aTempBar = aTempC >= 50 ? 100 : Math.round(aTempC * 2 + 15) || 'N/A';

  const loadPower = formatValue(assetDetails?.loadPower);
  const powerGoal = formatValue(assetDetails?.pGoal);
  const batteryThroughput = formatValue(
    assetDetails?.lifeTimeEnergyFromBattery
  );
  const inverterStatus = assetDetails?.operationMode || 'N/A';
  const chargerStatus = assetDetails?.chargerStatus || 'N/A';

  return (
    <div className="flex flex-col h-full">
      <div className="modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer">
        <div
          ref={modalRef}
          className={`container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto 
        ${showAddChargeEvent ||
              showAddDischargeEvent ||
              showDeleteChargingEventModal ||
              showDeleteDischargingEventModal ||
              showOptOutEventModal
              ? 'overflow-y-hidden'
              : ''
            }`}
        >
          <header className="flex flex-col justify-center py-7 md:sticky top-0 bg-white z-50 drop-shadow relative lg:flex-row">
            <h1 className="text-2xl font-bold text-center flex-grow sm:mt-2 lg:mt-0">
              Real-Time Data: {assetName ? assetName : 'Asset'}
            </h1>
            <div className="flex flex-wrap md:flex-row flex-col justify-center align-center gap-1 sm:mt-2 lg:mt-0 md:items-center lg:mr-14">
              {enableUPS && (
                <button
                  className="rounded-md px-1 py-2 text-sm flex justify-center items-center mr-2 !min-w-[120px]"
                  onClick={() => {
                    toggleAssetDetails();
                    toggleAssetUpsDetails();
                  }}
                >
                  UPS
                </button>
              )}

              <button
                className="rounded-md px-1 py-2 text-sm flex justify-center items-center mr-2 !min-w-[120px] !bg-gray-500 !hover:filter-none"
                onClick={() => { }}
                disabled
              >
                BESS
              </button>
              {enableSecondary && (
                <button
                  className="rounded-md px-1 py-2 text-sm flex justify-center items-center !min-w-[120px]"
                  onClick={() => {
                    toggleAssetDetails();
                    toggleAssetSecondaryDetails();
                  }}
                >
                  {secondaryName || 'Secondary'}
                </button>
              )}
            </div>
            <span
              className="absolute cursor-pointer top-8 right-4"
              type="button"
              role="button"
              onClick={() => toggleAssetDetails()}
            >
              <FiX size={28} />
            </span>
          </header>
          {isAssetDataLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Loader classNames="w-32 h-32" />
            </div>
          ) : (
            <div className="p-6 flex flex-col justify-between">
              <div className="flex flex-row justify-end">
                {isShowExportAssetData && (
                  <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap mb-4">
                    <p className="mr-2 font-medium text-lg">
                      Export asset BESS historical data:
                    </p>
                    <div className=" flex items-center justify-between md:justify-center">
                      <p className="mr-1">Start:</p>
                      <div className=" flex items-center justify-around w-44 h-10 relative cursor-pointer">
                        <Flatpickr
                          name={`${assetName}-start`}
                          placeholder="mm.dd.yyyy hh:mm"
                          className="calendar-input"
                          onChange={handleStartDateTimeChange}
                          onClose={handleCalendarClose}
                          options={{
                            dateFormat: 'm.d.Y H:i',
                            enableTime: true,
                            maxDate: nowAssetTime,
                            disableMobile: true,
                          }}
                        />
                        <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                        <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                      </div>
                    </div>

                    <div className="flex items-center justify-between md:justify-center">
                      <p className="mr-1">End:</p>
                      <div className="flex items-center justify-around w-44 h-10 relative cursor-pointer">
                        <Flatpickr
                          placeholder="mm.dd.yyyy hh:mm"
                          className="calendar-input"
                          name={`${assetName}-end`}
                          onChange={handleEndDateTimeChange}
                          onClose={handleCalendarClose}
                          options={{
                            dateFormat: 'm.d.Y H:i',
                            enableTime: true,
                            minDate: assetDataStartDateTime
                              ? new Date(assetDataStartDateTime)
                              : null,
                            maxDate: nowAssetTime,
                            disableMobile: true,
                          }}
                        />
                        <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                        <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                      </div>
                    </div>

                    {isAssetHistoricalDataLoading ? (
                      <Loader classNames="w-5 h-5 mr-2 ml-1" />
                    ) : (
                      <CSVLink
                        data={transformedData}
                        filename={`${assetName} BESS historical data ${rangeForFileName}.csv`}
                        className="csv-link mr-2 ml-1"
                      >
                        <button
                          type="button"
                          className="save-as-csv rounded-md px-1 py-1 text-xs"
                          title="Save as CSV"
                          disabled={
                            !assetDataStartDateTime || !assetDataEndDateTime
                          }
                        >
                          <IconContext.Provider value={{ color: '#00000' }}>
                            <PiFileCsv size={24} />
                          </IconContext.Provider>
                        </button>
                      </CSVLink>
                    )}
                  </div>
                )}
                <FiChevronDown
                  size={18}
                  className={`cursor-pointer transition-transform ${isShowExportAssetData ? 'rotate-180' : null
                    }`}
                  onClick={toggleShowExportAssetData}
                  title="Export asset historical data"
                />
              </div>
              <div
                className={`col-span-1 grid ${assetDetailsData.enableDPS ? 'grid-cols-7' : 'grid-cols-6'
                  } gap-8 mb-8`}
              >
                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                    Inverter's Operation Mode
                  </h2>
                  <div className="h-[125px] w-[110px]">
                    <InverterOperation />
                  </div>
                  <p className="text-center text-sm font-medium sm:text-base">
                    Inverter Status: {inverterStatus}
                  </p>
                  <p className="text-center text-sm font-medium sm:text-base">
                    Charger Status: {chargerStatus}
                  </p>
                </div>

                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                    Backup Power
                  </h2>
                  <div className="h-[125px] w-[110px]">
                    <LoadPower />
                  </div>
                  <div>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {loadPower} kW
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                    Battery throughput
                  </h2>
                  <div className="h-[125px] w-[110px]">
                    <BatteryThroughput />
                  </div>
                  <div>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {batteryThroughput} kWh
                    </p>
                  </div>
                </div>
                {assetDetailsData.enableDPS && (
                  <div className="flex flex-col items-center">
                    <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                      Target Power
                    </h2>
                    <div className="h-[125px] w-[110px]">
                      <TargetPower className="relative z-20 h-full w-full " />
                    </div>
                    <div>
                      <p className="text-center text-sm font-medium sm:text-base">
                        {powerGoal} kW
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                    Battery State of Charge
                  </h2>
                  <div className="relative overflow-hidden h-[125px] w-[110px]">
                    <Battery className="relative z-10 h-full w-full" />
                    <div className="absolute left-2 w-[100px] bottom-4 h-[89px]">
                      <div
                        className="absolute w-[100px] iconFilling"
                        style={{ height: `${life}%`, bottom: 0 }}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {life}%
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                    Battery Temperature
                  </h2>
                  <div className="relative overflow-hidden h-[125px] w-[110px]">
                    <Thermometer className="relative z-10 h-full w-full" />
                    <div
                      className="absolute left-2 bottom-0 w-[100px] iconFilling"
                      style={{ height: `${tempBar}%` }}
                    />
                  </div>
                  <div>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {tempC}&deg;C
                    </p>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {tempF}&deg;F
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-center font-medium text-xl md:h-[82px] md:h-[82px] lg:h-[80px] xl:h-[55px]">
                    Ambient Temperature
                  </h2>
                  <div className="relative overflow-hidden h-[125px] w-[110px]">
                    <ThermometerSun className="relative z-10 h-full w-full" />
                    <div
                      className="absolute left-2 bottom-0 w-[100px] iconFilling"
                      style={{ height: `${aTempBar}%` }}
                    />
                  </div>
                  <div>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {aTempC}&deg;C
                    </p>
                    <p className="text-center text-sm font-medium sm:text-base">
                      {aTempF}&deg;F
                    </p>
                  </div>
                </div>
              </div>
              <ResponsiveGridLayout
                className="layout"
                layouts={{ lg: layout ? layout : [] }}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
                rowHeight={30}
                margin={[15, 15]}
                measureBeforeMount={true}
                useCSSTransforms={false}
                draggableHandle=".draggableHandle"
                draggableCancel=".dashboardNav--cancel"
                onLayoutChange={handleLayoutChange}
              >
                {layout?.map(element => {
                  // Make a human-readable title
                  const title = idToText(element.i);

                  // Inject child components dynamically
                  let componentSwitch;
                  let filter;
                  let widgetDateFilter;
                  let widgetDateRange;
                  let createEvent;
                  let createEventAndFilters;
                  let widgetDateTimeFilter;
                  let widgetYearFilter;
                  let year;
                  let timezone;
                  let assetDifferenceFromUtcInMinutes;

                  switch (element.i) {
                    case 'BESSGridPower':
                      widgetDateFilter = dateFilter;
                      widgetDateRange = {
                        start: powerStartDate,
                        end: powerEndDate,
                      };
                      componentSwitch = isGridPowerLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <GridPower
                          assetName={assetName}
                          gridPower={gridPower}
                          powerStartDate={powerAssetStartDate}
                          powerEndDate={powerAssetEndDate}
                          assetTimezone={assetTimezone}
                          assetDifferenceFromUtc={assetDifferenceFromUtc}
                        />
                      );
                      break;
                    case 'batteryVoltage/Current':
                      widgetDateFilter = dateFilter;
                      widgetDateRange = {
                        start: batteryStartDate,
                        end: batteryEndDate,
                      };
                      componentSwitch = isBatteryVoltageCurrentLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <VoltageCurrent
                          assetName={assetName}
                          batteryVoltageCurrent={batteryVoltageCurrent}
                          batteryStartDate={batteryAssetStartDate}
                          batteryEndDate={batteryAssetEndDate}
                          assetDifferenceFromUtc={assetDifferenceFromUtc}
                          assetTimezone={assetTimezone}
                        />
                      );
                      break;
                    case 'BESSPerformance':
                      widgetDateFilter = dateFilter;
                      widgetDateRange = {
                        start: powerSocStartDate,
                        end: powerSocEndDate,
                      };

                      componentSwitch = isPowerSocLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <PowerSoc
                          assetName={assetName}
                          isDpsActive={assetDetailsData.enableDPS}
                          powerSoc={powerSoc}
                          powerSocStartDate={powerSocAssetStartDate}
                          powerSocEndDate={powerSocAssetEndDate}
                          assetTimezone={assetTimezone}
                          assetDifferenceFromUtc={assetDifferenceFromUtc}
                        />
                      );
                      break;
                    case 'maxPower':
                      widgetYearFilter = yearFilter;
                      year = currentYear;

                      componentSwitch = isMaxPowerLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <MaxPower
                          maxPower={maxPower}
                          year={year}
                          assetName={assetName}
                        />
                      );
                      break;
                    case 'chargingEvents':
                      createEventAndFilters = true;
                      timezone = assetTimezone;
                      assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;
                      widgetDateTimeFilter = dateFilterEvents;
                      widgetDateRange = {
                        start: chargeStartDateTime,
                        end: chargeEndDateTime,
                      };
                      componentSwitch = getChargingEventsLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <ChargingEvents
                          toggleDeleteChargingEventModal={
                            toggleDeleteChargingEventModal
                          }
                          setActiveChargingEventID={setActiveChargingEventID}
                          isWidget={true}
                          assetDifferenceFromUtc={assetDifferenceFromUtc}
                        />
                      );
                      break;
                    case 'dischargingEvents':
                      createEventAndFilters = true;
                      timezone = assetTimezone;
                      assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;
                      widgetDateTimeFilter = dateFilterEvents;
                      widgetDateRange = {
                        start: dischargeStartDateTime,
                        end: dischargeEndDateTime,
                      };
                      componentSwitch = getDischargingEventsLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <DischargingEvents
                          toggleDeleteDischargingEventModal={
                            toggleDeleteDischargingEventModal
                          }
                          toggleOptOutEventModal={toggleOptOutEventModal}
                          setActiveDischargingEventID={
                            setActiveDischargingEventID
                          }
                          userDetails={userDetails}
                          assetDifferenceFromUtc={assetDifferenceFromUtc}
                        />
                      );
                      break;
                    default:
                      componentSwitch = null;
                      break;
                  }

                  return (
                    <div
                      key={element.i}
                      className="container-border grid-component__container overflow-hidden"
                    >
                      <Widget
                        title={title}
                        id={element.i}
                        hasFilter={filter}
                        dateFilter={widgetDateFilter}
                        dateTimeFilter={widgetDateTimeFilter}
                        yearFilter={widgetYearFilter}
                        dateRange={widgetDateRange}
                        year={year}
                        child={componentSwitch}
                        hasCreateEvent={createEvent}
                        hasCreateEventAndFilters={createEventAndFilters}
                        toggleCreateChargeEvent={toggleCreateChargeEvent}
                        toggleCreateDischargeEvent={toggleCreateDischargeEvent}
                        assetTimezone={timezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtcInMinutes}
                        assetName={assetName}
                        assetTimeEndOfToday={assetTimeEndOfToday}
                      />
                    </div>
                  );
                })}
              </ResponsiveGridLayout>
            </div>
          )}

          {!!showAddChargeEvent && (
            <Modal
              toggleModal={toggleCreateChargeEvent}
              isSmall={true}
              child={
                <CreateChargeEvent
                  assetName={assetName}
                  toggleModal={toggleCreateChargeEvent}
                  userDetails={userDetails}
                  dateFilterEvents={dateFilterEvents}
                  chargeStartDateTime={chargeStartDateTime}
                  chargeEndDateTime={chargeEndDateTime}
                  toggleDeleteChargingEventModal={
                    toggleDeleteChargingEventModal
                  }
                  setActiveChargingEventID={setActiveChargingEventID}
                  enableDPS={assetDetailsData.enableDPS}
                  assetDifferenceFromUtc={assetDifferenceFromUtc}
                />
              }
            />
          )}

          {!!showAddDischargeEvent && (
            <Modal
              toggleModal={toggleCreateDischargeEvent}
              isSmall={true}
              child={
                <CreateDischargeEvent
                  assetName={assetName}
                  toggleModal={toggleCreateDischargeEvent}
                  userDetails={userDetails}
                  dateFilterEvents={dateFilterEvents}
                  dischargeStartDateTime={dischargeStartDateTime}
                  dischargeEndDateTime={dischargeEndDateTime}
                  assetDifferenceFromUtc={assetDifferenceFromUtc}
                />
              }
            />
          )}

          {!!showDeleteChargingEventModal && (
            <Modal
              toggleModal={toggleDeleteChargingEventModal}
              isSmall={true}
              child={
                <DeleteChargingEvent
                  activeChargingEventID={activeChargingEventID}
                  toggleModal={toggleDeleteChargingEventModal}
                  dateFilterEvents={dateFilterEvents}
                  chargeStartDateTime={chargeStartDateTime}
                  chargeEndDateTime={chargeEndDateTime}
                  assetName={assetName}
                />
              }
            />
          )}

          {!!showDeleteDischargingEventModal && (
            <Modal
              toggleModal={toggleDeleteDischargingEventModal}
              isSmall={true}
              child={
                <DeleteDischargingEvent
                  activeDischargingEventID={activeDischargingEventID}
                  toggleModal={toggleDeleteDischargingEventModal}
                  dateFilterEvents={dateFilterEvents}
                  dischargeStartDateTime={dischargeStartDateTime}
                  dischargeEndDateTime={dischargeEndDateTime}
                  assetName={assetName}
                />
              }
            />
          )}

          {!!showOptOutEventModal && (
            <Modal
              toggleModal={toggleOptOutEventModal}
              isSmall={true}
              child={
                <OptOutDischargingEvent
                  activeDischargingEventID={activeDischargingEventID}
                  toggleModal={toggleOptOutEventModal}
                  dateFilterEvents={dateFilterEvents}
                  dischargeStartDateTime={dischargeStartDateTime}
                  dischargeEndDateTime={dischargeEndDateTime}
                  assetName={assetName}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BessDashboard;
