const DefaultSingleBessLayout = [
  {
    i: 'BESSPerformance',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'chargingEvents',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'dischargingEvents',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    i: 'scheduledDR',
    maxW: 40,
    maxH: 40,
    minH: 6,
    minW: 2,
    w: 12,
    h: 8,
    x: 0,
    y: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d2',
    userID: '670e2806c00e11901ec09a5a',
    i: 'dailySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 0,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d3',
    userID: '670e2806c00e11901ec09a5a',
    i: 'weeklySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 0,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d4',
    userID: '670e2806c00e11901ec09a5a',
    i: 'monthlySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 8,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d5',
    userID: '670e2806c00e11901ec09a5a',
    i: 'yearlySecondWattnodePower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 8,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d6',
    userID: '670e2806c00e11901ec09a5a',
    i: 'dailyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 16,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d7',
    userID: '670e2806c00e11901ec09a5a',
    i: 'weeklyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 16,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d8',
    userID: '670e2806c00e11901ec09a5a',
    i: 'monthlyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 24,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985d9',
    userID: '670e2806c00e11901ec09a5a',
    i: 'yearlyHouseLoadPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 24,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985da',
    userID: '670e2806c00e11901ec09a5a',
    i: 'dailyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 32,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985db',
    userID: '670e2806c00e11901ec09a5a',
    i: 'weeklyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 32,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985dc',
    userID: '670e2806c00e11901ec09a5a',
    i: 'monthlyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 0,
    y: 40,
    __v: 0,
  },
  {
    _id: '671b9d667333ccd8d1e985dd',
    userID: '670e2806c00e11901ec09a5a',
    i: 'yearlyHouseMeteringPower',
    maxW: 100,
    maxH: 100,
    minH: 6,
    minW: 2,
    w: 6,
    h: 8,
    x: 6,
    y: 40,
    __v: 0,
  },
];

module.exports = DefaultSingleBessLayout;
