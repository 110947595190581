import { useRef, useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import formatRangeForFileNameLocalTime from '../../helpers/formatDate/formatRangeForFileNameLocalTime';
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import getTimezoneFromCoordinates from '../../helpers/formatDate/getTimezoneFromCoordinates';
import { FiX, FiChevronDown } from 'react-icons/fi';
import prepLayout from '../../utils/PrepLayout';
import {
  fetchAccountLoadData,
  fetchAccountMeteringData,
  fetchAccountSolarData,
  fetchYearlyAccountLoadData,
  fetchYearlyAccountMeteringData,
  fetchYearlyAccountSolarData,
} from '../../services/graphService';
import {
  calculateAssetTimeRange,
  calculateDateRangeInUTC,
  calculateMonthlyAssetTimeRange,
  calculateMonthRangeInUTC,
  calculateWeeklyAssetTimeRange,
} from '../../helpers/formatDate/calculateAssetTimeRange';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { validateAndConvertDatesToUTC } from '../../helpers/formatDate/validateAndConvertDatesToUTC';
import { idToText } from '../../helpers/widget/idToText';
import Widget from '../widgets/Widget';
import SolarYearly from '../widgets/SolarYearly';
import api from '../../api/axios.config';
import Flatpickr from 'react-flatpickr';
import Loader from '../Loader';
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';
import { CSVLink } from 'react-csv';
import { BsCalendarEvent } from 'react-icons/bs';
import { PiFileCsv } from 'react-icons/pi';
import { IconContext } from 'react-icons';
import SolarDaily from '../widgets/SolarDaily';
import SolarWeekly from '../widgets/SolarWeekly';
import SolarMonthly from '../widgets/SolarMonthly';
import MeteringDaily from '../widgets/MeteringDaily';
import MeteringWeekly from '../widgets/MeteringWeekly';
import LoadDaily from '../widgets/LoadDaily';
import LoadWeekly from '../widgets/LoadWeekly';
import { ReactComponent as SolarPowerNow } from '../../assets/Solar_Power_Now.svg';
import { ReactComponent as ProductionToday } from '../../assets/Production_Today.svg';
import { ReactComponent as LifetimeProduction } from '../../assets/Lifetime_Production.svg';
import { ReactComponent as MyHouse } from '../../assets/My_House.svg';
import { ReactComponent as HouseMetering } from '../../assets/House_Metering.svg';
import { ReactComponent as SolarEnergy } from '../../assets/Solar_Energy.svg';
import UnderIconBar from '../pageComponents/assetIconComponents/UnderIconBar';
import { getSolarAssetData } from '../../services/assetService';
import { formatValue } from '../../helpers/icons/formatValue';

const SecondWattnodeDashboard = ({
  assetData: assetDetailsData = {},
  toggleAssetDetails,
  toggleAssetUpsDetails,
  toggleAssetBessDetails,
}) => {
  const modalRef = useRef();
  const [dailyPowerData, setDailyPowerData] = useState([]);
  const [dailyLoadData, setDailyLoadData] = useState([]);
  const [weeklyPowerData, setWeeklyPowerData] = useState([]);
  const [weeklyMeteringData, setWeeklyMeteringData] = useState([]);
  const [weeklyLoadData, setWeeklyLoadData] = useState([]);
  const [monthlyPowerData, setMonthlyPowerData] = useState([]);
  const [monthlyMeteringData, setMonthlyMeteringData] = useState([]);
  const [monthlyLoadData, setMonthlyLoadData] = useState([]);
  const [yearlyPowerData, setYearlyPowerData] = useState([]);
  const [yearlyMeteringData, setYearlyMeteringData] = useState([]);
  const [yearlyLoadData, setYearlyLoadData] = useState([]);
  const [dailyMeteringData, setDailyMeteringData] = useState([]);
  const [IsMonthlySolarLoading, setIsMonthlySolarLoading] = useState(true);
  const [IsMonthlyMeteringLoading, setIsMonthlyMeteringLoading] =
    useState(true);
  const [IsMonthlyLoadLoading, setIsMonthlyLoadLoading] = useState(true);
  const {
    name: assetName,
    enableBESS,
    enableUPS,
    secondaryName,
    facilityType,
  } = assetDetailsData;
  const [layout, setLayout] = useState(null);
  const [isShowExportAssetData, setIsShowExportAssetData] = useState(false);
  const [isAssetHistoricalDataLoading, setIsAssetHistoricalDataLoading] =
    useState(false);
  const [solarDailyStartDate, setSolarDailyStartDate] = useState('');
  const [solarDailyEndDate, setSolarDailyEndDate] = useState('');
  const [loadDailyStartDate, setLoadDailyStartDate] = useState('');
  const [loadDailyEndDate, setLoadDailyEndDate] = useState('');
  const [solarWeeklyStartDate, setSolarWeeklyStartDate] = useState('');
  const [solarWeeklyEndDate, setSolarWeeklyEndDate] = useState('');
  const [meteringWeeklyStartDate, setMeteringWeeklyStartDate] = useState('');
  const [meteringWeeklyEndDate, setMeteringWeeklyEndDate] = useState('');
  const [loadWeeklyStartDate, setLoadWeeklyStartDate] = useState('');
  const [loadWeeklyEndDate, setLoadWeeklyEndDate] = useState('');
  const [meteringDailyStartDate, setMeteringDailyStartDate] = useState('');
  const [meteringDailyEndDate, setMeteringDailyEndDate] = useState('');
  const [assetData, setAssetData] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [assetDataStartDateTime, setAssetDataStartDateTime] = useState('');
  const [assetDataEndDateTime, setAssetDataEndDateTime] = useState('');
  const now = new Date();
  const [isDailySolarLoading, setIsDailySolarLoading] = useState(true);
  const [isDailyLoadLoading, setIsDailyLoadLoading] = useState(true);
  const [isWeeklySolarLoading, setIsWeeklySolarLoading] = useState(true);
  const [isWeeklyMeteringLoading, setIsWeeklyMeteringLoading] = useState(true);
  const [isWeeklyLoadLoading, setIsWeeklyLoadLoading] = useState(true);
  const [isDailyMeteringLoading, setIsDailyMeteringLoading] = useState(true);
  const [isYearlySolarLoading, setIsYearlySolarLoading] = useState(true);
  const [isYearlyMeteringLoading, setIsYearlyMeteringLoading] = useState(true);
  const [isYearlyLoadLoading, setIsYearlyLoadLoading] = useState(true);

  const assetDifferenceFromUtc = getUtcOffsetDifference(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );
  const assetTimezone = getTimezoneFromCoordinates(
    assetDetailsData?.gpsCoordinates?.latitude,
    assetDetailsData?.gpsCoordinates?.longitude
  );

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      modalRef.current?.scrollTo(0, scrollPosition);
    }, 0);
  };
  useEffect(() => {
    scrollToPreviousPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToPreviousPosition]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const startOfDay = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  endOfDay.setHours(23, 59, 59, 999);
  const [backupLoadAssetStartDate, setPowerAssetStartDate] = useState();
  const [backupLoadAssetEndDate, setPowerAssetEndDate] = useState();
  const [meteringAssetStartDate, setMeteringAssetStartDate] = useState();
  const [meteringAssetEndDate, setMeteringAssetEndDate] = useState();
  const [loadAssetStartDate, setLoadAssetStartDate] = useState();
  const [loadAssetEndDate, setLoadAssetEndDate] = useState();
  const [powerAssetStartWeekDate, setPowerAssetStartWeekDate] = useState('');
  const [powerAssetEndWeekDate, setPowerAssetEndWeekDate] = useState('');
  const [meteringAssetStartWeekDate, setMeteringAssetStartWeekDate] =
    useState('');
  const [meteringAssetEndWeekDate, setMeteringAssetEndWeekDate] = useState('');
  const [loadAssetStartWeekDate, setLoadAssetStartWeekDate] = useState('');
  const [loadAssetEndWeekDate, setLoadAssetEndWeekDate] = useState('');
  const [solarIconsData, setSolarIconsData] = useState(null);
  const [isSolarIconsDataLoading, setIsSolarIconsDataLoading] = useState(true);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const username = localStorage.getItem('cdnzUser');
  const token = localStorage.getItem('cdnzAccessToken');

  const solarPowerNow = formatValue(solarIconsData?.solarPowerNow);
  const productionToday = formatValue(solarIconsData?.productionToday);
  const lifetimeProduction = formatValue(solarIconsData?.lifetimeProduction);
  const myHouseNow = formatValue(solarIconsData?.myHouseNow);
  const myHouseToday = formatValue(solarIconsData?.myHouseToday);
  const myHouseThisMonth = formatValue(solarIconsData?.myHouseThisMonth);
  const myHouseTillNow = formatValue(solarIconsData?.myHouseThisTillNow);
  const solarEnergyNow = formatValue(solarIconsData?.solarEnergyNow);
  const solarEnergyToday = formatValue(solarIconsData?.solarEnergyToday);
  const solarEnergyThisMonth = formatValue(
    solarIconsData?.solarEnergyThisMonth
  );
  const solarEnergyTillNow = formatValue(
    solarIconsData?.solarEnergyThisTillNow
  );
  const houseMeteringNow = formatValue(solarIconsData?.houseMeteringNow);
  const houseMeteringToday = formatValue(solarIconsData?.houseMeteringToday);
  const houseMeteringThisMonth = formatValue(
    solarIconsData?.houseMeteringThisMonth
  );
  const houseMeteringTillNow = formatValue(
    solarIconsData?.houseMeteringThisTillNow
  );
  const handleStartDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataStartDateTime(date.toString());
  };
  const handleScroll = () => {
    const position = modalRef.current.scrollTop;
    setScrollPosition(position);
  };

  const fetchSolarIconsData = async () => {
    try {
      const solarData = await getSolarAssetData(assetName);
      setSolarIconsData(solarData);
    } catch (error) {
      console.error('Error fetching Solar data:', error);
    } finally {
      setIsSolarIconsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialChartsData();
    fetchLayoutData();
    fetchSolarIconsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Date filter functionality for charts
  const dateFilter = async (element, startDateParam) => {
    if (!element || !startDateParam) return;
    let endDateParam;
    if (
      element === 'dailySecondWattnodePower' ||
      element === 'dailyHouseMeteringPower' ||
      element === 'dailyHouseLoadPower'
    ) {
      endDateParam = new Date(startDateParam);
      endDateParam.setHours(23, 59, 59, 999);
    }

    if (
      element === 'weeklySecondWattnodePower' ||
      element === 'weeklyHouseMeteringPower' ||
      element === 'weeklyHouseLoadPower'
    ) {
      endDateParam = new Date(startDateParam);
      endDateParam.setDate(endDateParam.getDate() + 6);
      endDateParam.setHours(0, 0, 0, 0);
    }

    const { startToUTC, endToUTC, startOfDayUTC, endOfDayUTC } =
      calculateDateRangeInUTC(
        startDateParam,
        endDateParam,
        assetDifferenceFromUtc
      );

    handleScroll();

    if (element === 'dailySecondWattnodePower') {
      try {
        setIsDailySolarLoading(true);
        const dailyPowerResult = await fetchAccountSolarData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyPowerData(dailyPowerResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setSolarDailyStartDate(startDateParam);
        setSolarDailyEndDate(endDateParam);
        setPowerAssetStartDate(startOfDayUTC);
        setPowerAssetEndDate(endOfDayUTC);
        setIsDailySolarLoading(false);
      }
    }
    if (element === 'dailyHouseLoadPower') {
      try {
        setIsDailyLoadLoading(true);
        const dailyLoadResult = await fetchAccountLoadData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyLoadData(dailyLoadResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setLoadDailyStartDate(startDateParam);
        setLoadDailyEndDate(endDateParam);
        setLoadAssetStartDate(startOfDayUTC);
        setLoadAssetEndDate(endOfDayUTC);
        setIsDailyLoadLoading(false);
      }
    }
    if (element === 'dailyHouseMeteringPower') {
      try {
        setIsDailyMeteringLoading(true);
        const dailyMeteringPowerResult = await fetchAccountMeteringData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setDailyMeteringData(dailyMeteringPowerResult);
      } catch (error) {
        console.error('Error fetching backup load data:', error);
      } finally {
        setMeteringDailyStartDate(startDateParam);
        setMeteringDailyEndDate(endDateParam);
        setMeteringAssetStartDate(startOfDayUTC);
        setMeteringAssetEndDate(endOfDayUTC);
        setIsDailyMeteringLoading(false);
      }
    }

    if (element === 'weeklySecondWattnodePower') {
      try {
        setIsWeeklySolarLoading(true);
        const weeklyPowerResult = await fetchAccountSolarData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyPowerData(weeklyPowerResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setSolarWeeklyStartDate(startDateParam);
        setSolarWeeklyEndDate(endDateParam);
        setPowerAssetStartWeekDate(startOfDayUTC);
        setPowerAssetEndWeekDate(endOfDayUTC);
        setIsWeeklySolarLoading(false);
      }
    }

    if (element === 'weeklyHouseMeteringPower') {
      try {
        setIsWeeklyMeteringLoading(true);
        const weeklyMeteringResult = await fetchAccountMeteringData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyMeteringData(weeklyMeteringResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setMeteringWeeklyStartDate(startDateParam);
        setMeteringWeeklyEndDate(endDateParam);
        setMeteringAssetStartWeekDate(startOfDayUTC);
        setMeteringAssetEndWeekDate(endOfDayUTC);
        setIsWeeklyMeteringLoading(false);
      }
    }
    if (element === 'weeklyHouseLoadPower') {
      try {
        setIsWeeklyLoadLoading(true);
        const weeklyLoadResult = await fetchAccountLoadData(
          assetName,
          startToUTC,
          endToUTC,
          'daily'
        );
        setWeeklyLoadData(weeklyLoadResult);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoadWeeklyStartDate(startDateParam);
        setLoadWeeklyEndDate(endDateParam);
        setLoadAssetStartWeekDate(startOfDayUTC);
        setLoadAssetEndWeekDate(endOfDayUTC);
        setIsWeeklyLoadLoading(false);
      }
    }
  };

  // Fetch initial charts data
  const fetchInitialChartsData = async () => {
    const {
      startToUTC,
      endToUTC,
      startOfDay,
      endOfDay,
      startAssetTime,
      endAssetTime,
    } = calculateAssetTimeRange(assetDifferenceFromUtc);

    const {
      startToUTCWeek,
      endToUTCWeek,
      startOfWeek,
      endOfWeek,
      startAssetTimeWeek,
      endAssetTimeWeek,
    } = calculateWeeklyAssetTimeRange(assetDifferenceFromUtc);

    const { startOfMonth, endOfMonth } = calculateMonthlyAssetTimeRange();

    try {
      const dailyPowerResult = await fetchAccountSolarData(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyPowerData(dailyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setPowerAssetStartDate(startAssetTime);
      setPowerAssetEndDate(endAssetTime);
      setSolarDailyStartDate(startOfDay);
      setSolarDailyEndDate(endOfDay);
      setIsDailySolarLoading(false);
    }
    try {
      const dailyLoadResult = await fetchAccountLoadData(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyLoadData(dailyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoadAssetStartDate(startAssetTime);
      setLoadAssetEndDate(endAssetTime);
      setLoadDailyStartDate(startOfDay);
      setLoadDailyEndDate(endOfDay);
      setIsDailyLoadLoading(false);
    }
    try {
      const dailyMeteringPowerResult = await fetchAccountMeteringData(
        assetName,
        startToUTC,
        endToUTC,
        'daily'
      );
      setDailyMeteringData(dailyMeteringPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setMeteringAssetStartDate(startAssetTime);
      setMeteringAssetEndDate(endAssetTime);
      setMeteringDailyStartDate(startOfDay);
      setMeteringDailyEndDate(endOfDay);
      setIsDailyMeteringLoading(false);
    }
    try {
      const weeklyMeteringResult = await fetchAccountMeteringData(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyMeteringData(weeklyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setMeteringAssetStartWeekDate(startAssetTimeWeek);
      setMeteringAssetEndWeekDate(endAssetTimeWeek);
      setMeteringWeeklyStartDate(startOfWeek);
      setMeteringWeeklyEndDate(endOfWeek);
      setIsWeeklyMeteringLoading(false);
    }
    try {
      const weeklyLoadResult = await fetchAccountLoadData(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyLoadData(weeklyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoadAssetStartWeekDate(startAssetTimeWeek);
      setLoadAssetEndWeekDate(endAssetTimeWeek);
      setLoadWeeklyStartDate(startOfWeek);
      setLoadWeeklyEndDate(endOfWeek);
      setIsWeeklyLoadLoading(false);
    }
    try {
      const weeklyPowerResult = await fetchAccountSolarData(
        assetName,
        startToUTCWeek,
        endToUTCWeek,
        'daily'
      );
      setWeeklyPowerData(weeklyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setPowerAssetStartWeekDate(startAssetTimeWeek);
      setPowerAssetEndWeekDate(endAssetTimeWeek);
      setSolarWeeklyStartDate(startOfWeek);
      setSolarWeeklyEndDate(endOfWeek);
      setIsWeeklySolarLoading(false);
    }
    try {
      const monthlyPowerResult = await fetchAccountSolarData(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyPowerData(monthlyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlySolarLoading(false);
    }
    try {
      const monthlyMeteringResult = await fetchAccountMeteringData(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyMeteringData(monthlyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlyMeteringLoading(false);
    }
    try {
      const monthlyLoadResult = await fetchAccountLoadData(
        assetName,
        startOfMonth,
        endOfMonth,
        'monthly'
      );
      setMonthlyLoadData(monthlyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsMonthlyLoadLoading(false);
    }
    try {
      const yearlyPowerResult = await fetchYearlyAccountSolarData(
        assetName,
        currentSolarYearOnly
      );
      setYearlyPowerData(yearlyPowerResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlySolarLoading(false);
    }
    try {
      const yearlyMeteringResult = await fetchYearlyAccountMeteringData(
        assetName,
        currentMeteringYearOnly
      );
      setYearlyMeteringData(yearlyMeteringResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlyMeteringLoading(false);
    }

    try {
      const yearlyLoadResult = await fetchYearlyAccountLoadData(
        assetName,
        currentLoadYearOnly
      );
      setYearlyLoadData(yearlyLoadResult);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsYearlyLoadLoading(false);
    }

    setIsFirstTimeLoading(false);
  };

  const yearFilter = async (element, year) => {
    if (!element || !year) return;

    handleScroll();
    if (element === 'yearlySecondWattnodePower') {
      try {
        setIsYearlySolarLoading(true);
        const yearlySolarResult = await fetchYearlyAccountSolarData(
          assetName,
          year
        );
        setYearlyPowerData(yearlySolarResult);
      } catch (error) {
        console.error('Error fetching yearly solar data:', error);
      } finally {
        setCurrentSolarYearOnly(year);
        setIsYearlySolarLoading(false);
      }
    }
    if (element === 'yearlyHouseMeteringPower') {
      try {
        setIsYearlyMeteringLoading(true);
        const yearlyMeteringResult = await fetchYearlyAccountMeteringData(
          assetName,
          year
        );
        setYearlyMeteringData(yearlyMeteringResult);
      } catch (error) {
        console.error('Error fetching yearly solar data:', error);
      } finally {
        setCurrentMeteringYearOnly(year);
        setIsYearlyMeteringLoading(false);
      }
    }
    if (element === 'yearlyHouseLoadPower') {
      try {
        setIsYearlyLoadLoading(true);
        const yearlyLoadResult = await fetchYearlyAccountLoadData(
          assetName,
          year
        );
        setYearlyLoadData(yearlyLoadResult);
      } catch (error) {
        console.error('Error fetching yearly solar data:', error);
      } finally {
        setCurrentLoadYearOnly(year);
        setIsYearlyLoadLoading(false);
      }
    }
  };

  const monthFilter = async (element, year, month) => {
    if (!element || !year || !month) return;

    const { startOfMonth, endOfMonth } = calculateMonthRangeInUTC(
      year,
      month,
    );

    handleScroll();
    if (element === 'monthlySecondWattnodePower') {
      try {
        setIsMonthlySolarLoading(true);
        const monthlyPowerResult = await fetchAccountSolarData(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyPowerData(monthlyPowerResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentSolarYear(year);
        setCurrentSolarMonth(month);
        setIsMonthlySolarLoading(false);
      }
    }
    if (element === 'monthlyHouseMeteringPower') {
      try {
        setIsMonthlyMeteringLoading(true);
        const monthlyMeteringResult = await fetchAccountMeteringData(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyMeteringData(monthlyMeteringResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentMeteringYear(year);
        setCurrentMeteringMonth(month);
        setIsMonthlyMeteringLoading(false);
      }
    }
    if (element === 'monthlyHouseLoadPower') {
      try {
        setIsMonthlyLoadLoading(true);
        const monthlyLoadResult = await fetchAccountLoadData(
          assetName,
          startOfMonth,
          endOfMonth,
          'monthly'
        );
        setMonthlyLoadData(monthlyLoadResult);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setCurrentLoadYear(year);
        setCurrentLoadMonth(month);
        setIsMonthlyLoadLoading(false);
      }
    }
  };

  const handleEndDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataEndDateTime(date.toString());
  };
  const toggleShowExportAssetData = () => {
    setScrollPosition(0);
    setIsShowExportAssetData(!isShowExportAssetData);
  };

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(layouts, layout, `secondWattnodeDashboardLayout`);
    }
  };

  const getHistoricalData = () => {
    const validatedDates = validateAndConvertDatesToUTC(
      assetDataStartDateTime,
      assetDataEndDateTime,
      assetDifferenceFromUtc
    );

    if (!validatedDates) {
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    setIsAssetHistoricalDataLoading(true);

    // Fetch asset historical data
    api
      .post(
        `/device/second-wattnode/export`,
        {
          startDate: formattedStartDateUTC,
          endDate: formattedEndDateUTC,
          assetName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      )
      .then(res => {
        setAssetData(res.data.secondWattnodeData);
        setIsAssetHistoricalDataLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch asset historical data', notifyOptions);
        setIsAssetHistoricalDataLoading(false);
      });
  };

  const rangeForFileName = formatRangeForFileNameLocalTime(
    new Date(assetDataStartDateTime),
    new Date(assetDataEndDateTime)
  );

  const transformedData = transformDataForCSV(
    assetData,
    assetDifferenceFromUtc,
    ['myHouse', 'solarEnergy', 'houseMetering']
  );

  const nowAssetTime = new Date(
    now.getTime() - (-now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000
  );
  const [currentSolarYearOnly, setCurrentSolarYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentMeteringYearOnly, setCurrentMeteringYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentLoadYearOnly, setCurrentLoadYearOnly] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentSolarYear, setCurrentSolarYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentSolarMonth, setCurrentSolarMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const [currentMeteringYear, setCurrentMeteringYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentMeteringMonth, setCurrentMeteringMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const [currentLoadYear, setCurrentLoadYear] = useState(
    nowAssetTime.getFullYear()
  );
  const [currentLoadMonth, setCurrentLoadMonth] = useState(
    nowAssetTime.getMonth() + 1
  );
  const handleCalendarClose = () => {
    getHistoricalData();
  };

  const fetchLayoutData = () => {
    api
      .get(`device/second-wattnode/layout`, {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      })
      .then(res => {
        setLayout(res?.data);
      })
      .catch(() => {
        Notify.warning(
          'Layout with dps data are not loaded. Please try later.',
          notifyOptions
        );
      });
  };

  const additionalLoaders = isFirstTimeLoading ? (isDailyLoadLoading || isDailyMeteringLoading || isDailySolarLoading ||
    isWeeklyLoadLoading || isWeeklyMeteringLoading || isWeeklySolarLoading || isYearlyLoadLoading ||
    isYearlyMeteringLoading || isYearlySolarLoading || IsMonthlyLoadLoading || IsMonthlyMeteringLoading ||
    IsMonthlySolarLoading) : false;

  const ResponsiveGridLayout = WidthProvider(Responsive);

  return (
    <div className="modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer">
      <div
        ref={modalRef}
        className="container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto"
      >
        <header className="flex flex-col justify-center py-7 md:sticky top-0 bg-white z-50 drop-shadow relative lg:flex-row">
          <h1 className="text-2xl font-bold text-center flex-grow sm:mt-2 lg:mt-0">
            Real-Time Data: {assetName ? assetName : 'Asset'}
          </h1>
          <div className="flex flex-wrap md:flex-row flex-col justify-center align-center gap-1 sm:mt-2 lg:mt-0 md:items-center lg:mr-14">
            {enableUPS && (
              <button
                className="rounded-md px-1 py-2 text-sm flex justify-center items-center md:mr-2 !min-w-[120px]"
                onClick={() => {
                  toggleAssetDetails();
                  toggleAssetUpsDetails();
                }}
              >
                UPS
              </button>
            )}
            {enableBESS && (
              <button
                className="rounded-md px-1 py-2 text-sm flex justify-center items-center md:mr-2 !min-w-[120px]"
                onClick={() => {
                  toggleAssetDetails();
                  toggleAssetBessDetails();
                }}
              >
                BESS
              </button>
            )}
            <button
              className="rounded-md px-1 py-2 text-sm flex justify-center items-center !min-w-[120px] !bg-gray-500"
              onClick={() => { }}
              disabled
            >
              {secondaryName || 'Secondary'}
            </button>
          </div>
          <span
            className="absolute cursor-pointer top-8 right-4"
            type="button"
            role="button"
            onClick={() => toggleAssetDetails()}
          >
            <FiX size={28} />
          </span>
        </header>
        {isSolarIconsDataLoading || additionalLoaders ? (
          <div className="w-full h-full flex justify-center items-center">
            <Loader classNames="w-32 h-32" />
          </div>
        ) : (
          <div className="p-6 flex flex-col justify-between">
            <div className="flex flex-row justify-end">
              {isShowExportAssetData && (
                <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap mb-4">
                  <p className="mr-2 font-medium text-lg">
                    {`Export ${secondaryName || 'Secondary'} historical data:`}
                  </p>
                  <div className=" flex items-center justify-between md:justify-center">
                    <p className="mr-1">Start:</p>
                    <div className=" flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        name={`${assetName}-start`}
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        onChange={handleStartDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between md:justify-center">
                    <p className="mr-1">End:</p>
                    <div className="flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        name={`${assetName}-end`}
                        onChange={handleEndDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          minDate: assetDataStartDateTime
                            ? new Date(assetDataStartDateTime)
                            : null,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  {isAssetHistoricalDataLoading ? (
                    <Loader classNames="w-5 h-5 mr-2 ml-1" />
                  ) : (
                    <CSVLink
                      data={transformedData}
                      filename={`${assetName} SOLAR historical data ${rangeForFileName}.csv`}
                      className="csv-link mr-2 ml-1"
                    >
                      <button
                        type="button"
                        className="save-as-csv rounded-md px-1 py-1 text-xs"
                        title="Save as CSV"
                        disabled={
                          !assetDataStartDateTime || !assetDataEndDateTime
                        }
                      >
                        <IconContext.Provider value={{ color: '#00000' }}>
                          <PiFileCsv size={24} />
                        </IconContext.Provider>
                      </button>
                    </CSVLink>
                  )}
                </div>
              )}
              <FiChevronDown
                size={18}
                className={`cursor-pointer transition-transform ${isShowExportAssetData ? 'rotate-180' : null
                  }`}
                onClick={toggleShowExportAssetData}
                title="Export asset historical data"
              />
            </div>

            <div
              className={`flex flex-wrap md:justify-between justify-center items-center md:mx-20 md:mb-4`}
            >
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center h-[40px] sm:h-[50px] font-medium text-xl">
                  My {facilityType || 'Site'}
                </h2>
                <div className="h-[150px] w-[150px]">
                  <MyHouse />
                </div>
                <div>
                  <UnderIconBar
                    now={myHouseNow}
                    today={myHouseToday}
                    thisMonth={myHouseThisMonth}
                    tillNow={myHouseTillNow}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center h-[40px] sm:h-[50px] font-medium text-xl">
                  {secondaryName || 'Secondary'} Energy
                </h2>
                <div className="h-[150px] w-[150px]">
                  <SolarEnergy />
                </div>
                <div>
                  <UnderIconBar
                    now={solarEnergyNow}
                    today={solarEnergyToday}
                    thisMonth={solarEnergyThisMonth}
                    tillNow={solarEnergyTillNow}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center h-[40px] sm:h-[50px] font-medium text-xl">
                  {facilityType || 'Site'} Metering
                </h2>
                <div className="h-[150px] w-[150px]">
                  <HouseMetering />
                </div>
                <div>
                  <UnderIconBar
                    now={houseMeteringNow}
                    today={houseMeteringToday}
                    thisMonth={houseMeteringThisMonth}
                    tillNow={houseMeteringTillNow}
                  />
                </div>
              </div>
            </div>

            <div
              className={`flex flex-wrap md:justify-between justify-center items-center mt-4 mx-32 mb-8 gap-4`}
            >
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center font-medium text-xl md:h-[40px]">
                  {secondaryName || 'Secondary'} Power Now
                </h2>
                <div className="h-[150px] w-[150px]">
                  <SolarPowerNow />
                </div>
                <div className="mt-10">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {solarPowerNow} kW
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center font-medium text-xl md:h-[40px]">
                  {secondaryName || 'Secondary'} Production Today
                </h2>
                <div className="h-[150px] w-[150px]">
                  <ProductionToday />
                </div>
                <div className="mt-10">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {productionToday} kWh
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-center">
                <h2 className="font-bold text-center md:h-[40px] font-medium text-xl">
                  Lifetime Production
                </h2>
                <div className="h-[150px] w-[150px]">
                  <LifetimeProduction />
                </div>
                <div className="mt-10">
                  <p className="text-center text-sm font-medium sm:text-base">
                    {lifetimeProduction} MWh
                  </p>
                </div>
              </div>
            </div>
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: layout }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
              rowHeight={30}
              margin={[15, 15]}
              measureBeforeMount={true}
              useCSSTransforms={false}
              draggableHandle=".draggableHandle"
              draggableCancel=".dashboardNav--cancel"
              onLayoutChange={handleLayoutChange}
            >
              {layout?.map(element => {
                const title = idToText(element.i);

                // Inject child components dynamically
                let componentSwitch;
                let filter;
                let widgetDateFilter;
                let widgetDateRange;
                let createEvent;
                let createEventAndFilters;
                let widgetDateTimeFilter;
                let widgetYearFilter;
                let year;
                let month;
                let timezone;
                let dailyFilter;
                let assetDifferenceFromUtcInMinutes;
                let additionalTitle;
                let widgetMonthFilter;
                let isMaxDayWeek;

                assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;

                switch (element.i) {
                  case 'yearlySecondWattnodePower':
                    additionalTitle = `Yearly ${secondaryName || 'Secondary'} Energy`;
                    widgetYearFilter = yearFilter;
                    year = currentSolarYearOnly;
                    componentSwitch = isYearlySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarYearly
                        year={year}
                        maxPower={yearlyPowerData}
                        assetName={assetName}
                        energyName={secondaryName || 'Secondary'}
                      />
                    );
                    break;
                  case 'yearlyHouseMeteringPower':
                    additionalTitle = `Yearly ${facilityType || 'Site'} Metering Energy`;
                    widgetYearFilter = yearFilter;
                    year = currentMeteringYearOnly;
                    componentSwitch = isYearlyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarYearly
                        year={year}
                        maxPower={yearlyMeteringData}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Metering`}
                      />
                    );
                    break;
                  case 'yearlyHouseLoadPower':
                    additionalTitle = `Yearly ${facilityType || 'Site'} Load Energy`;
                    widgetYearFilter = yearFilter;
                    year = currentLoadYearOnly;
                    componentSwitch = isYearlyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarYearly
                        year={year}
                        maxPower={yearlyLoadData}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Load`}
                      />
                    );
                    break;
                  case 'dailySecondWattnodePower':
                    additionalTitle = `Daily ${secondaryName || 'Secondary'} Power`;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: solarDailyStartDate,
                      end: solarDailyEndDate,
                    };
                    componentSwitch = isDailySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarDaily
                        assetName={assetName}
                        backupLoadStartDate={backupLoadAssetStartDate}
                        backupLoadEndDate={backupLoadAssetEndDate}
                        backupLoad={dailyPowerData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        secondaryName={secondaryName || 'Secondary'}
                      />
                    );
                    break;
                  case 'dailyHouseLoadPower':
                    additionalTitle = `Daily ${facilityType || 'Site'} Load Power`;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: loadDailyStartDate,
                      end: loadDailyEndDate,
                    };
                    componentSwitch = isDailyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <LoadDaily
                        assetName={assetName}
                        backupLoadStartDate={loadAssetStartDate}
                        backupLoadEndDate={loadAssetEndDate}
                        backupLoad={dailyLoadData}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                    break;
                  case 'weeklySecondWattnodePower':
                    additionalTitle = `Weekly ${secondaryName || 'Secondary'} Power`;
                    isMaxDayWeek = true;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: solarWeeklyStartDate,
                      end: solarWeeklyEndDate,
                    };
                    componentSwitch = isWeeklySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarWeekly
                        assetName={assetName}
                        backupLoad={weeklyPowerData}
                        backupLoadStartDate={powerAssetStartWeekDate}
                        backupLoadEndDate={powerAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        secondaryName={secondaryName || 'Secondary'}
                      />
                    );
                    break;

                  case 'dailyHouseMeteringPower':
                    additionalTitle = `Daily ${facilityType || 'Site'} Metering Power`;
                    dailyFilter = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: meteringDailyStartDate,
                      end: meteringDailyEndDate,
                    };
                    componentSwitch = componentSwitch =
                      isDailyMeteringLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <MeteringDaily
                          assetName={assetName}
                          backupLoadStartDate={meteringAssetStartDate}
                          backupLoadEndDate={meteringAssetEndDate}
                          backupLoad={dailyMeteringData}
                          assetTimezone={assetTimezone}
                          assetDifferenceFromUtc={assetDifferenceFromUtc}
                        />
                      );
                    break;

                  case 'weeklyHouseMeteringPower':
                    additionalTitle = `Weekly ${facilityType || 'Site'} Metering Power`;
                    dailyFilter = true;
                    isMaxDayWeek = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: meteringWeeklyStartDate,
                      end: meteringWeeklyEndDate,
                    };
                    componentSwitch = isWeeklyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <MeteringWeekly
                        assetName={assetName}
                        backupLoad={weeklyMeteringData}
                        backupLoadStartDate={meteringAssetStartWeekDate}
                        backupLoadEndDate={meteringAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                    break;

                  case 'weeklyHouseLoadPower':
                    additionalTitle = `Weekly ${facilityType || 'Site'} Load Power`;
                    dailyFilter = true;
                    isMaxDayWeek = true;
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: loadWeeklyStartDate,
                      end: loadWeeklyEndDate,
                    };
                    componentSwitch = isWeeklyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <LoadWeekly
                        assetName={assetName}
                        backupLoad={weeklyLoadData}
                        backupLoadStartDate={loadAssetStartWeekDate}
                        backupLoadEndDate={loadAssetEndWeekDate}
                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                    break;

                  case 'monthlySecondWattnodePower':
                    additionalTitle = `Monthly ${secondaryName || 'Secondary'} Energy`;
                    year = currentSolarYear;
                    month = currentSolarMonth;
                    widgetMonthFilter = monthFilter;
                    componentSwitch = IsMonthlyMeteringLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarMonthly
                        powerMonthly={monthlyPowerData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={secondaryName || 'Secondary'}
                      />
                    );
                    break;
                  case 'monthlyHouseMeteringPower':
                    additionalTitle = `Monthly ${facilityType || 'Site'} Metering Energy`;
                    year = currentMeteringYear;
                    month = currentMeteringMonth;
                    widgetMonthFilter = monthFilter;
                    componentSwitch = IsMonthlySolarLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarMonthly
                        powerMonthly={monthlyMeteringData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Metering`}
                      />
                    );
                    break;
                  case 'monthlyHouseLoadPower':
                    additionalTitle = `Monthly ${facilityType || 'Site'} Load Energy`;
                    year = currentLoadYear;
                    month = currentLoadMonth;
                    widgetMonthFilter = monthFilter;
                    componentSwitch = IsMonthlyLoadLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : (
                      <SolarMonthly
                        powerMonthly={monthlyLoadData}
                        month={month}
                        year={year}
                        assetName={assetName}
                        energyName={`${facilityType || 'Site'} Load`}
                      />
                    );
                    break;
                  default:
                    return null;
                }

                return (
                  <div
                    key={element.i}
                    className="container-border grid-component__container overflow-hidden"
                  >
                    <Widget
                      title={additionalTitle ? additionalTitle : title}
                      id={element.i}
                      hasFilter={filter}
                      dateFilter={widgetDateFilter}
                      dateTimeFilter={widgetDateTimeFilter}
                      yearFilter={widgetYearFilter}
                      monthFilter={widgetMonthFilter}
                      dateRange={widgetDateRange}
                      year={year}
                      month={month}
                      isMaxDayWeek={isMaxDayWeek}
                      dailyFilter={dailyFilter}
                      child={componentSwitch}
                      hasCreateEvent={createEvent}
                      hasCreateEventAndFilters={createEventAndFilters}
                      assetTimezone={timezone}
                      assetDifferenceFromUtc={assetDifferenceFromUtcInMinutes}
                      assetName={assetName}
                    />
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondWattnodeDashboard;
